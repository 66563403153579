import React, { useState } from "react";
import {

} from "@mantine/core";
import { IconChevronLeft, IconChevronRight } from "@tabler/icons";
import { useNavigate, useRoutes } from "react-router-dom";
import { LinksGroupProps } from "@interface/models";
import { NavigationLinksGroup } from "./NavigationGroup";


export function NavigationLinks({ paths, onClickLink }: { paths: LinksGroupProps[], onClickLink?: () => void }) {
  let [activeKey, setActiveKey] = useState("/");
  let navigate = useNavigate();

  const links = paths
    .map((element) => {
      element.isActive = activeKey == element.link;
      element.onChangePath = (path: any) => {
        setActiveKey(path);
        if (onClickLink) {
          onClickLink();
        }
      };
      return element;
    })
    .map((item) => <NavigationLinksGroup {...item} key={item.label}
    />);

  //@ts-ignore
  return <React.Fragment>{links}</React.Fragment>;
}
