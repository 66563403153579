import { Box, Container, Stack } from "@mantine/core";
import { SectionHeader } from "@ui/organisms/common-widgets/SectionHeader";
import { RenderPlatformBanner } from "@ui/organisms/utils/covers";
import ReactMarkdown from 'react-markdown'
import { useUi } from "../../../hooks/use-ui";

export default () => {
  useUi();
  
  return (
    <Box>
      <RenderPlatformBanner title="Etoju Terms and Conditions" />

      <Container>
        <ReactMarkdown
          children={`Welcome to Etoju! These Terms and Conditions ("Terms") govern your use of our website and mobile applications (together, the "Platform"), as well as any services, features, or content we offer (collectively, the "Services"). By accessing or using the Platform or Services, you agree to be bound by these Terms, as well as our Privacy Policy, which is incorporated by reference into these Terms. If you do not agree to these Terms, you may not use the Platform or Services.
## Eligibility
To use the Platform and Services, you must be at least 18 years of age and capable of entering into a binding agreement. By using the Platform and Services, you represent and warrant that you meet these eligibility requirements.
## Use of the Platform and Services
Etoju grants you a limited, non-exclusive, non-transferable, revocable license to access and use the Platform and Services for your own personal, non-commercial use. You may not use the Platform or Services for any other purpose without our prior written consent.
You agree to comply with all applicable laws and regulations in connection with your use of the Platform and Services, and to not engage in any conduct that may interfere with or disrupt the Platform or Services or harm Etojus.
## User Content
The Platform may allow you to submit or post content, including but not limited to ratings, reviews, and comments ("User Content"). You retain ownership of any User Content you submit or post, but you grant Etoju a non-exclusive, transferable, sublicensable, royalty-free, worldwide license to use, copy, modify, create derivative works based on, distribute, publicly display, publicly perform, and otherwise exploit in any manner such User Content in all formats and distribution channels now known or hereafter devised (including in connection with the Platform and Etoju's business and on third-party sites and services), without further notice to or consent from you, and without the requirement of payment to you or any other person or entity.
You represent and warrant that you have all rights necessary to grant the licenses granted in this section, and that your User Content will not infringe or violate the rights of any third party or violate any applicable laws or regulations.
## Payment and Fees
You agree to pay all fees and charges associated with your use of the Services in accordance with the pricing and payment terms presented to you for such Services. Etojus the right to change its pricing and payment terms at any time, in its sole discretion.
## Disclaimer of Warranties
THE PLATFORM AND SERVICES ARE PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED. Etoju DISCLAIMS ALL WARRANTIES, INCLUDING WITHOUT LIMITATION IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. Etoju DOES NOT WARRANT THAT THE PLATFORM OR SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THE PLATFORM OR SERVICES ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.
## Limitation of Liability
IN NO EVENT SHALL Etoju BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING LOST PROFITS, ARISING OUT OF OR RELATED TO YOUR USE OR INABILITY TO USE THE PLATFORM OR SERVICES, EVEN IF Etoju HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. Etoju'S TOTAL LIABILITY TO YOU FOR ALL CLAIMS ARISING OUT OF OR RELATED TO YOUR USE OF THE PLATFORM OR SERVICES SHALL NOT`} />

      </Container>
    </Box>
  );
};
