import { MantineTheme, MantineThemeOverride } from "@mantine/core";

export const MaintineThemeConfig: MantineThemeOverride = {
  colorScheme: "light",
  fontFamily: "'Sharp Sans', sans-serif",
  fontFamilyMonospace: "'Sharp Sans', sans-serif",
  headings: { fontFamily: "'Sharp Sans', sans-serif" },
  primaryColor: "orange",
  primaryShade: { light: 5, dark: 8 },
  colors: {
    appdark: [
      "#0094c6",
      "#0085b2",
      "#00769e",
      "#00688b",
      "#F5A841",
      // "#004a63",
       "#003b4f", // stops here
      "#002c3b",
      "#001e28",
      "#000f14",
      "#000000",
    ],
    orange: [
      "#fef4e7",
      "#fbdeb6",
      "#f9c886",
      "#f6b155",
      "#f49b25",
      "#da820b", // stops here
      "#aa6509",
      "#794806",
      "#492b04",
      "#180e01",
    ],
    blue: [
      "#eaf2fb",
      "#c0d9f2",
      "#96bfe9",
      "#6ba6e0",
      "#418cd7",
      "#2873be", // stops here
      "#1f5994",
      "#164069",
      "#0d263f",
      "#040d15",
    ],
  },
  components: {
    Button: {
      defaultProps: {},
      styles(theme, params) {
        return {
          root: {
            ":disabled": {
              background: theme.colors.gray[6],
              color: theme.colors.gray[3],
            },
            borderRadius: 8,
          },
        };
      },
    },
    Paper: {
      styles(theme, params) {
        return {
          root: {},
        };
      },
    },
    Tabs: {
      defaultProps: {
        color: "blue",
      },
      styles(theme, params) {
        return {
          tab: {
            ":hover": {
              background: "transparent",
              borderColor: "transparent",
            },
          },
        };
      },
    },
    TextInput: {
      defaultProps: {},
      styles(theme, params) {
        return {
          input: {
            // background: theme.fn.darken(theme.colors.darkBlue[5], .2),
          },
        };
      },
    },
    Select: {
      styles(theme, params) {
        return {};
      },
    },
    Modal: {
      styles(theme, params) {
        return {
          modal: {
            // background: theme.colors.darkBlue[5],
            borderColor: "transparent",
            alignSelf: "center",
          },
        };
      },
    },
  },
};
