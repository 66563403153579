import { Box, Container, Stack } from "@mantine/core";
import { SectionHeader } from "@ui/organisms/common-widgets/SectionHeader";
import { RenderPlatformBanner, RenderPlatformFancyBanner } from "@ui/organisms/utils/covers";
import ReactMarkdown from 'react-markdown'
import { useUi } from "../../../hooks/use-ui";
export default () => {
  useUi()
  return (
    <Box>
      <RenderPlatformFancyBanner title="Refund Policy"
        caption="At Etoju, we want to ensure that our customers are satisfied with our services. If for any reason you are not satisfied with your booking, we offer refunds as described below."
      />

      <Container>
        <ReactMarkdown children={`


## Cancellations and Refunds

If you need to cancel your booking, you can do so through the Etoju app or website. The following refund policy applies:

- Cancellation at least 24 hours before the scheduled trip start time: full refund
- Cancellation less than 24 hours before the scheduled trip start time: no refund

If you experience a problem during your ride that prevents you from completing the trip, please contact our support team immediately for assistance. We may issue a partial or full refund depending on the circumstances.

## Payment Processing

Refunds are processed back to the original payment method used to make the booking. It may take up to 10 business days for the refund to appear in your account.

## Changes to This Policy

We may update this Refund Policy from time to time to reflect changes in our practices or applicable laws. We encourage you to review this policy periodically to stay informed about our refund process.

If you have any questions or concerns about our Refund Policy, please contact us at [contact@Etoju.com](mailto:contact@Etoju.com).
`} />
      </Container>
    </Box>
  );
};
