import { useState } from "react";
import { AppShell, Box, Navbar, useMantineTheme } from "@mantine/core";
import { AppOutlet } from "./AppOutlet";
import MainHeader from "@ui/organisms/main-header/MainHeader";
import AppFooter from "@ui/organisms/common-widgets/AppFooter";
import AppHeader from "@ui/organisms/common-widgets/AppHeader";
import PublicMobileNavigation from "@ui/organisms/public-widgets/PublicMobileNavigation";
import MainAppNavigation from "@ui/organisms/main-navigation/MainNavigation";
import { appPublicMobileNavigationLinks } from "../../config/link";
import { NavigationLinks } from "@ui/organisms/navigation-widgets/NavigationLinks";

type MainAppShellProps = {};

export const DefaultShell: React.FC<MainAppShellProps> = ({ }) => {
  const theme = useMantineTheme();
  const [opened, setOpened] = useState(false);
  return (
    <AppShell
      styles={{
        main: {
          paddingLeft: 0,
          paddingRight: 0,
          margin: 0,
          paddingTop: 80,
          minHeight: "100vh",
        },
      }}
      footer={<AppFooter />}
      header={<AppHeader opened={!opened} setOpened={setOpened} />}
      navbar={
        <Navbar
          p="md"
          height={"calc(100vh - 70px)"}
          // hiddenBreakpoint="sm"
          hidden={false}
          style={{ display: opened == true ? "block" : "none" }}
          width={{ sm: 200, lg: 300 }}
        >
          <NavigationLinks paths={appPublicMobileNavigationLinks} onClickLink={() => {
            setOpened(false)
          }} />
        </Navbar>
      }
    >
      <AppOutlet />
    </AppShell>
  );
};
