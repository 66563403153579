import {
  ActionIcon,
  Avatar,
  Box,
  Center,
  Divider,
  Group,
  Paper,
  Stack,
  Title,
  useMantineTheme,
  Text,
} from "@mantine/core";
import { HorizontalKeyValuePair } from "@ui/molecules/text";
import { Edit } from "iconsax-react";
import { useNavigate } from "react-router-dom";
import { formatCurrency } from "../../../../utils";
import { authManager } from "@store/account/auth";
import { reviewsManager } from "@store/my-review";
import { bookingsManager } from "@store/bookings-manager";
import { observer } from "mobx-react";

export const ProfileSummaryCard = observer(() => {
  let theme = useMantineTheme();
  let navigate = useNavigate();

  let { first_name, last_name, profile_photo, account_status, email } =
    authManager.user;
  return (
    <Box>
      <Paper
        p={"18px"}
        sx={(theme) => {
          return {
            width: "100%",
            background: "white",
            borderTop: "5px solid orange",
          };
        }}
        shadow="md"
      >
        <Stack>
          <Center>
            <Avatar size={"xl"} src={profile_photo} />
          </Center>
          <Box sx={{ textAlign: "center" }}>
            <Title size={"md"}>
              {first_name} {last_name}
            </Title>
            <Text>{email}</Text>
            <Text size="xs" color={"dimmed"}>
              {account_status}
            </Text>
          </Box>
          <Divider />
          <HorizontalKeyValuePair
            label="Completed rides"
            value={
              bookingsManager.bookings.filter((_) => _.status == "COMPLETED")
                .length + ""
            }
          />
          <HorizontalKeyValuePair
            label="Pending rides"
            value={
              bookingsManager.bookings.filter((_) => _.status == "PENDING")
                .length + ""
            }
          />
          <HorizontalKeyValuePair
            label="Reviews"
            //@ts-ignore
            value={authManager.user?.metrics?.reviews_count ?? "0" + ""}
          />
        </Stack>
      </Paper>
    </Box>
  );
});
