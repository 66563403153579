import { openConfirmModal, } from "@mantine/modals";
import { Text } from "@mantine/core"

export function useConfirm() {
    function confirmGeneric(title: string, body: string, action: () => void) {
        openConfirmModal({
            title: title,
            children: (
                <Text size="sm">
                    {body}
                </Text>
            ),
            labels: { confirm: "Confirm", cancel: "Cancel" },
            onCancel: () => console.log("Cancel"),
            onConfirm: () => action()
        });
    }

    function confirmOutdatedPayment(action: () => void) {
        confirmGeneric("Important!!!", "Payment for trip reservation is outdated as trip has started or is completed. Confirm  to continue to payment.", action)
    }

    function confirmUpdateDriverDocumetation(action: () => void) {
        confirmGeneric("Important!!!", "Driver verification documents updated are subject to review. You will no longer be able to manage your driver profile until updates are approved.", action)
    }


    function confirmDelete(action: () => void) {
        openConfirmModal({
            title: "Please confirm your action",
            children: (
                <Text size="sm">
                    Are you sure you want to delete this item? This
                    action cannot be undone.
                </Text>
            ),
            labels: { confirm: "Confirm", cancel: "Cancel" },
            onCancel: () => console.log("Cancel"),
            onConfirm: () => action()

        });
    }

    function confirmUpdate(action: () => void) {
        openConfirmModal({
            title: "Please confirm your action",
            children: (
                <Text size="sm">
                    Are you sure you want to update this resource? This
                    action cannot be undone.
                </Text>
            ),
            labels: { confirm: "Confirm", cancel: "Cancel" },
            onCancel: () => console.log("Cancel"),
            onConfirm: () => action()

        });
    }

    return { confirmDelete, confirmUpdate, confirmOutdatedPayment, confirmUpdateDriverDocumetation, confirmGeneric }
}