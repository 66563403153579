import {
  Box,
  Button,
  Center,
  Container,
  Grid,
  Paper,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { SectionHeader } from "@ui/organisms/common-widgets/SectionHeader";
import { RenderPlatformBanner } from "@ui/organisms/utils/covers";
import { observer } from "mobx-react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { useNavigate, useParams } from "react-router-dom";
import { authController } from "../../../config/sdk";
import { useEffect, useState } from "react";
import { Verify } from "iconsax-react";
import { showNotification } from "@mantine/notifications";
import { authManager } from "@store/account/auth";
import { useUi } from "../../../hooks/use-ui";
import { LoginDtoAccountTypeEnum } from "../../../sdk/auth";

export default observer(() => {
  let { userId, activationToken } = useParams();
  let [status, setStatus] = useState("intial");
  const navigate = useNavigate();

  useUi();

  return (
    <Box>
      <RenderPlatformBanner title="Activate account" caption="" />
      <Container>
        <Grid justify="center">
          <Grid.Col md={10} sx={{ textAlign: "center" }}>
            <Stack>
              <Center>
                <Verify color="gray" size={100} variant="Bulk" />
              </Center>
              <Title>Verify your email address</Title>
              <Text>
                Please click on the button below to complete email verification{" "}
              </Text>
              <Button
                color="green"
                size="lg"
                onClick={() => {
                  authController
                    .authControllerActivate(userId ?? "", activationToken ?? "")
                    .then(async (res) => {
                     await authManager.loadProfile();
                      showNotification({
                        message: "Email address has been verified",
                      });
                      if (authManager.status == "AUTHENTICATED") {
                        if(authManager.user.account_type==LoginDtoAccountTypeEnum.Driver){
                          navigate("/account/driver");
                          return 
                        }
                        navigate("/account");
                      } else {
                        navigate("/signin");
                      }
                    
                    })
                    .catch((err) => {
                      showNotification({ message: "Verification failed" });
                    });
                }}
              >
                Complete verification
              </Button>
            </Stack>
          </Grid.Col>
        </Grid>
      </Container>
    </Box>
  );
});
