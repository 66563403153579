import { Box, Title, Text, Container, useMantineTheme, Stack } from "@mantine/core";
import GetstartedCroped from "@ui/png/getstarted.jpg"
export const RenderPlatformBanner = ({ caption, title, cover }: { title?: string, caption?: string, cover?: any }) => {
  const theme = useMantineTheme();
  return (
    <Box sx={{ background: theme.colors.dark[8], marginBottom: 24 }}>
      <Container>
        <Box py={42} sx={{ textAlign: "center", color: "white" }}>
          <Title>{title ?? "Etoju"}</Title>
          <Text>{caption ?? "Wherever you're headed, Etoju gets you there."}</Text>
        </Box>
      </Container>
    </Box>
  );
};


export const RenderPlatformFancyBanner = ({ caption, title, cover }: { title?: string, caption?: string, cover?: any }) => {
  const theme = useMantineTheme();
  return (
    <Box sx={{
      backgroundImage: `url(${cover ?? GetstartedCroped})`, backgroundPosition: "center", backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    }}>
      <Stack sx={{
        marginBottom: 24, height: "50vh",
        backgroundImage: "linear-gradient(transparent, white)"
      }} justify="center">
        <Container>
          <Box py={32} sx={{ textAlign: "center", color: "black" }}>
            <Title sx={{ fontSize: 50 }}>{title ?? "Etoju"}</Title>
            <Text>{caption ?? "Wherever you're headed, Etoju gets you there."}</Text>
          </Box>
        </Container>
      </Stack>
    </Box>
  );
};

