import { makeAutoObservable, runInAction } from "mobx";
import { bookingController } from "../config/sdk";
import { Booking } from "../sdk/catalog";

class BookingsManager {
  bookings: Booking[] = [];
  constructor() {
    makeAutoObservable(this);
  }
  async loadBookings() {
    try {
      //@ts-ignore
      let response = await bookingController.bookingControllerGetBookings();
      runInAction(() => {
        this.bookings = response.data;
      });
    } catch (err) {
      console.log(err);
    }
  }

  async completeRide() {
    try {
      //@ts-ignore
      await bookingController.bookingControllerCompleted();
      this.loadBookings();
    } catch (err) {
      console.log(err);
    }
  }


}

export const bookingsManager = new BookingsManager();
