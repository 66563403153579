import { makeAutoObservable, runInAction } from "mobx";
import { ticketsController, } from "../config/sdk";
import { Payout, Ticket } from "../sdk/catalog";

class Manager {
  items: Ticket[] = [];
  constructor() {
    makeAutoObservable(this);
  }

  loadItems() {
    ticketsController.ticketControllerGet().then((payload) => {
      runInAction(() => {
        this.items = payload.data;
      });
    });
  }
}

export const ticketsManager = new Manager();
