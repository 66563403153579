import { authManager } from "@store/account/auth";
import { Reservation } from "../../../sdk/catalog";
import { useConfirm } from "../../../hooks/use-confirm";
import { usePaystackPayment } from "react-paystack";
import { Button } from "@mantine/core";
import { tripController } from "../../../config/sdk";
import { useNavigate } from "react-router-dom";
import { useAlert } from "../../../hooks/use-alert";

export function PayforReservation({
  paymentObj,
  reservation,
  ignore = false,
  payLabel,
  trip_id,
}: {
  reservation: Reservation;
  paymentObj: { [key: string]: any };
  ignore?: boolean;
  payLabel?: string;
  trip_id?: string;
}) {
  let { confirmOutdatedPayment } = useConfirm();
  let navigate = useNavigate();

  let { alertGeneric } = useAlert();
  const config: any = {
    ...paymentObj,
    email: authManager.user.email,
  };

  // you can call this function anything
  const onSuccess = (reference: any) => {
    // Implementation for whatever you want to do with reference and after success call.
    navigate("/account");
  };

  // you can call this function anything
  const onClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    console.log("closed");
  };

  const initializePayment = usePaystackPayment(config);

  if (reservation.status == "PENDING" && ignore == false) {
    return <div>Awaiting driver</div>;
  }

  return (
    <div>
      <Button
        fullWidth
        onClick={async () => {
          if (trip_id != undefined) {
            let check = await tripController.tripControllerCanAcceptReservation(
              trip_id ?? "",
              { seats: reservation.seats }
            );
            if (check.data == false) {
              alertGeneric(
                "Ops!!!",
                "One or more of the seats your selected is/are no longer available... Kindly re-book to preserve a seat.",
                () => {
                  window.location.reload();
                }
              );
              return;
            }
          }
          tripController
            .tripControllerGetById(reservation.trip_id)
            .then((response) => {
              if (response.data.status == "PENDING") {
                //@ts-ignore
                initializePayment(onSuccess, onClose);
              } else {
                //@ts-ignore
                confirmOutdatedPayment(() =>
                  //@ts-ignore
                  initializePayment(onSuccess, onClose)
                );
              }
            })
            .catch((e) => {
              console.log(e);
            });
        }}
      >
        {payLabel ?? "Pay"}
      </Button>
    </div>
  );
}
