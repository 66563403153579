import { Paper, Center } from "@mantine/core";
import { Indicator } from "@mantine/core";
import VehicleSeatSvgComponent from "@ui/svg/VehicleSeatSvg";

export const SeatBadge = ({
  selected,
  selectedColor,
  unselectedColor,
  index,
}: {
  selected?: boolean;
  selectedColor?: string;
  unselectedColor?: string;
  size?: number;
  index?: number;
}) => {
  return (
    <Indicator disabled={selected == true ? false : true} position="bottom-end">
      <Paper withBorder radius={"xl"}>
        <Center p="6px">
          <VehicleSeatSvgComponent
            width={22}
            height={22}
            fill={
              // selected == true
              //   ? selectedColor ?? "gray"
              //   : unselectedColor ?? "green"
              selected == true ? "gray" : "green"
            }
          />
        </Center>
      </Paper>
    </Indicator>
  );
};
