import { Box, Button, Container, Group, JsonInput, Paper, Stack, TextInput, Textarea, Text, Avatar, ScrollArea, Center, useMantineTheme } from "@mantine/core";
import { useTicketChat } from "../../../hooks/use-ticket";
import { useParams } from "react-router-dom";
import { ticketsManager } from "@store/tickets";
import { useUserHook } from "../../../hooks/user-hook";
import { showNotification } from "@mantine/notifications";
import { AxiosError } from "axios"
import { authManager } from "@store/account/auth";
import { UpdateTicketStatusDtoStatusEnum } from "../../../sdk/catalog";
import { useUi } from "../../../hooks/use-ui";
import React from "react";


export default function () {
    const theme = useMantineTheme()
    let { ticket_id } = useParams()
    let ticket = ticketsManager.items.find(e => e.id == ticket_id);
    let { messages, setMessage, sendMessage, message } = useTicketChat(`ticket-${ticket_id}`);
    let { userProfile } = useUserHook(ticket?.publisher_id ?? "");
    useUi();

    return <Container>
        <Stack my={"12px"}>
            <Paper p="12px" shadow="md">
                <Group position="apart">
                    <Box>
                        <Text>Manage Ticket</Text>
                        <Group>
                            <Avatar src={userProfile?.profile_photo} />
                            <Text>{userProfile?.first_name} {userProfile?.last_name}</Text>
                        </Group>
                    </Box>
                    <Box sx={{ textAlign: "right" }}>
                        <Text size={"xs"} color="dimmed">created on</Text>
                        <Text>{new Date(ticket?.created_at ?? "").toDateString()}</Text>
                    </Box>
                </Group>
            </Paper>
            <Paper p={"12px"} shadow="md">
                <Stack>
                    <Center>
                        <Box sx={{ textAlign: "center" }}>
                            <Text>{ticket?.subject ?? "No subject"}</Text>
                            <Text color="dimmed" size={"sm"}>{ticket?.issue ?? "No body"}</Text>
                        </Box>
                    </Center>
                    <ScrollArea sx={{
                        height: "40vh", overflowY: "auto", border: `1px solid ${theme.colors.gray[4]}`, borderRadius: 4, background: theme.colors.gray[0],
                        padding: 12
                    }}>
                        <Stack >
                            {messages.map(element => {
                                return <ChatMessageRender entry={element} />
                            })}
                        </Stack>
                    </ScrollArea>
                    {ticket?.status == "PENDING" || ticket?.status == "PROCESSING" ? <React.Fragment>
                        <Group>
                            <Textarea size="xs" value={message} sx={{ flex: 1 }} onChange={(event) => {
                                setMessage(event.target.value)
                            }} />
                        </Group>
                        <Group position="right">
                            <Button size="xs" onClick={() => {
                                sendMessage()
                            }}>Send</Button>
                        </Group>
                    </React.Fragment> : null}
                </Stack>
            </Paper>
        </Stack>

    </Container>
}


function ChatMessageRender({ entry }: { entry: any }) {
    return <Box sx={{ textAlign: entry.user_id == authManager.user.id ? "right" : "left" }}>
        <Text >
            {entry.text}
        </Text>
        <Text color="dimmed" size={"xs"}>{new Date(entry.created_on).toDateString()}</Text>
    </Box>
}