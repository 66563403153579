import { useEffect, useState } from "react";
import { Driver, Trip, User } from "../../sdk/catalog";
import { driverController, siteTripsController, userController } from "../../config/sdk";



export function usePublicTrips(trip_id: string) {
    let [trip, setTrip] = useState<Trip>();
    let [driver, setDriver] = useState<User>();
    let [driverProfile, setDriverProfile] = useState<Driver>();

    function load() {
        siteTripsController
            .siteTripControllerGetById(trip_id ?? "")
            .then(async (response) => {
            
                setTrip(response.data);
                //@ts-ignore
                await loadDriverProfile(response.data.driver_id)
            })
            .catch((err) => {
            });
    }

    async function loadDriverProfile(driver_id: string) {
        userController.userControllerFindAccountById(driver_id)
            .then((response) => {
             
                setDriver(response.data);
            });

        driverController.driverControllerGetDriverProfile(driver_id)
            .then((response) => {
             
                setDriverProfile(response.data);
            });
    }
    useEffect(() => {
        load()
    }, []);

    return {
        trip, load,
        driver, driverProfile
    }
}