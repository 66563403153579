import {
  Box,
  Container,
  Grid,
  Stack,
  Title,
  Text,
  Button,
  Center,
  Group,
  Paper,
  useMantineTheme,
  MediaQuery,
} from "@mantine/core";
import { observer } from "mobx-react";
import { FindRideBox } from "./component/FindRideBox";
import womanjpg from "../../png/woman.jpg";
import getstartedjpg from "../../png/getstarted.jpg";
import { Like, Money, ShieldTick } from "iconsax-react";
import React from "react";
import { RenderPlatformBanner } from "@ui/organisms/utils/covers";
import { useUi } from "../../../hooks/use-ui";
export default observer(function HowItWorksScreen() {
  const theme = useMantineTheme();
  useUi()
  return (
    <Box sx={{ overflow: 'hidden' }}>
      {/* <RenderPlatformBanner title="Waitlist" /> */}
      <Box sx={{ border: "none", overflow: 'hidden' }}>
        <iframe
          width={"100%"} src="https://b48aa54b.sibforms.com/serve/MUIEAMMXunOIbtX8z2WEBaI20w4c5VNdPsJOiJ5AC-nj57CQYazVLS4pndOU_x47ueXREnZCea0K_oCyKdNbiValSgJrkj3hVF5OdLucJRkRKrZ8N8euv3dki055YhbE8taHuGOFSfxEKdSxKGfODeDRQhQb-AYPLOwiL_E3nVaTwbDdKH0octdeOXPWx9lOxxFyyGLXGX4VpcKQ" scrolling="auto" style={{
            display: "block", marginLeft: "auto", marginRight: "auto", maxWidth: "100%", border: "none",
            height: "100vh"
          }}></iframe>
      </Box>
    </Box>
  );
});
