import {
  Box,
  Button,
  Center,
  Modal,
  Stack,
  TextInput,
  Text,
  Group,
  Grid,
  Title,
  Paper,
} from "@mantine/core";
import {
  HorizontalKeyValuePair,
  VerticalKeyValuePair,
} from "@ui/molecules/text";
import { useReservationsHook } from "../../../../hooks/reservation-hook";
import { useMakeReservation } from "../../../../hooks/reservations/make-reservation";
import { Driver, Trip } from "../../../../sdk/catalog";
import { useDisclosure, useForceUpdate } from "@mantine/hooks";
import { formatCurrency, friendlyLocationStr } from "../../../../utils";
import { reservationManager } from "@store/my-reservations";
import { OnReservationSuccessful } from "./components/OnReservationSuccessful";
import { RenderSelectableSSeats } from "./components/RenderSelectableSeats";
import { RenderTripReservation } from "./components/TripReservation";
import { observer } from "mobx-react";
import { authManager } from "@store/account/auth";
import { showNotification } from "@mantine/notifications";
import { PayforReservation } from "@ui/organisms/reservation-widgets/PayforReservationButton";
import React from "react";

export const RedoReservation = observer(
  ({
    trip,
    driverProfile,
    handle,
  }: {
    trip: Trip;
    driverProfile: Driver;
    handle?: React.ReactNode;
  }) => {
    const [opened, { open, close }] = useDisclosure(false);
    let {
      reserveSeats,
      selectSeat,
      makeReservation,
      reservationSent,
      selectAllSeat,
      clear,
      paymentObj,
      reservation,
    } = useMakeReservation({ trip });

    let assertReservation = reservationManager.reservations.find(
      (_) => _.trip_id == trip.id
    );

    return (
      <Box>
        <Modal
          size={"lg"}
          opened={opened}
          onClose={() => {
            clear();
            close();
          }}
        >
          {reservationSent ? (
            <Stack>
              <OnReservationSuccessful
                reserveSeats={reserveSeats}
                trip={trip}
              />

              {paymentObj !== undefined && reservation != undefined ? (
                <PayforReservation
                  ignore={true}
                  reservation={reservation}
                  paymentObj={paymentObj}
                  payLabel="Pay now to reserve your seat"
                />
              ) : null}
              <Button
                variant="subtle"
                color="gray"
                onClick={() => {
                  clear();
                  close();
                }}
              >
                Close
              </Button>
            </Stack>
          ) : (
            <Stack>
              <Title sx={{ textAlign: "center", fontSize: 20 }}>
                Make reservation
              </Title>
              <Paper withBorder p="12px">
                <Stack sx={{}}>
                  <VerticalKeyValuePair
                    label="From"
                    value={friendlyLocationStr(trip.start_location)}
                  />
                  <VerticalKeyValuePair
                    label="To"
                    value={friendlyLocationStr(trip.end_location)}
                  />
                  <HorizontalKeyValuePair
                    label="Price Per seat"
                    value={formatCurrency(trip.trip_spec.price_per_seat)}
                  />
                </Stack>
              </Paper>
              <Grid justify={"stretch"}>
                <Grid.Col md={6}>
                  <Center>
                    <img
                      //@ts-ignore
                      src={driverProfile.vehicle_sketch.sketch_image_url}
                      style={{ display: "block", width: 100 }}
                    />
                  </Center>
                </Grid.Col>
                <Grid.Col md={6}>
                  <RenderSelectableSSeats
                    reserveSeats={reserveSeats}
                    selectAllSeat={selectAllSeat}
                    selectSeat={selectSeat}
                    driverProfile={driverProfile}
                    trip={trip}
                  />
                </Grid.Col>
              </Grid>
              <Button
                size="lg"
                onClick={async () => {
                  await makeReservation();
                  window.location.reload();
                }}
              >
                Book
              </Button>
            </Stack>
          )}
        </Modal>

        <div
          onClick={() => {
            open();
          }}
        >
          {handle}
        </div>
      </Box>
    );
  }
);
