import { AuthApi } from "../sdk/auth";
import axios from "axios";
import {
  BookingsControllerApi,
  PayoutControllerApi,
  ProfileControllerApi,
  ReservationControllerApi,
  RequestsControllerApi,
  UserControllerApi,
  ReviewControllerApi,
  TripControllerApi,
  SiteTripControllerApi,
  DriverControllerApi,
  UtilsControllerApi,
  TicketControllerApi
} from "../sdk/catalog";

let config = {
  isJsonMime: (mime: any) => {
    return true;
  },
};

let axiosConfig = axios.create({
  headers: {
    "X-API-KEY":
      "RA_X4pVOQ45wgcxmn3uOqQKdeAwZOCnwpaRHNJsbGb8NZBmzcFwPv5EI7NP4qyvlsbPFmF2QQq0tteoTxHEzYY2Y0bIIE1WXEvQcvc750JF4cJbGypyFqi9MRxGFYJDD",
  },
});

export let authController = new AuthApi(config, undefined, axiosConfig);

export let ticketsController = new TicketControllerApi(config, undefined, axiosConfig);

export let bookingController = new BookingsControllerApi(
  config,
  undefined,
  axiosConfig
);

export let driverController = new DriverControllerApi(
  config,
  undefined,
  axiosConfig
);
export let reservationController = new ReservationControllerApi(
  config,
  undefined,
  axiosConfig
);

export let siteTripsController = new SiteTripControllerApi(
  config,
  undefined,
  axiosConfig
);

export let reviewController = new ReviewControllerApi(
  config,
  undefined,
  axiosConfig
);
export let requestController = new RequestsControllerApi(
  config,
  undefined,
  axiosConfig
);

export let tripController = new TripControllerApi(
  config,
  undefined,
  axiosConfig
);

export let utilsController = new UtilsControllerApi(
  config,
  undefined,
  axiosConfig
);


export let userController = new UserControllerApi(
  config,
  undefined,
  axiosConfig
);
export let profileController = new ProfileControllerApi(
  config,
  undefined,
  axiosConfig
);
export let payoutController = new PayoutControllerApi(
  config,
  undefined,
  axiosConfig
);
export function configureClientSDK(token: string) {
  let config = {
    accessToken: token,
    isJsonMime: (mime: any) => {
      return true;
    },
  };

  authController = new AuthApi(config, undefined, axiosConfig);
  ticketsController = new TicketControllerApi(config, undefined, axiosConfig);
  bookingController = new BookingsControllerApi(config, undefined, axiosConfig);
  tripController = new TripControllerApi(config, undefined, axiosConfig);

  reservationController = new ReservationControllerApi(
    config,
    undefined,
    axiosConfig
  );
  reviewController = new ReviewControllerApi(config, undefined, axiosConfig);
  requestController = new RequestsControllerApi(config, undefined, axiosConfig);

  driverController = new DriverControllerApi(config, undefined, axiosConfig);

  userController = new UserControllerApi(config, undefined, axiosConfig);
  profileController = new ProfileControllerApi(config, undefined, axiosConfig);
  payoutController = new PayoutControllerApi(config, undefined, axiosConfig);

  localStorage.setItem("u-token", token);
}
