import { Box } from "@mantine/core";

export const FancySectionCover = ({
  cover,
  children,
}: {
  cover: any;
  children: React.ReactNode;
}) => {
  return (
    <Box
      style={{
        borderRadius: 24,
        paddingTop: "30%",
        background: "",
        backgroundImage: `url(${cover})`,
        // backgroundAttachment: "fixed",
        backgroundPosition: "center",
      }}
    >
      {children}
    </Box>
  );
};
