import React from "react";
import { BrowserRouter, BrowserRouter as Router } from "react-router-dom";
import { observer } from "mobx-react";
import { MantineProvider, Text, Box, Group } from "@mantine/core";
import { NotificationsProvider } from "@mantine/notifications";
import MainAppRoutes from "../routes/MainAppRoutes";
import { MaintineThemeConfig } from "../styles/mantine-config";
import { authManager } from "@store/account/auth";
import { MagicStar } from "iconsax-react";
import { NavigationProgress } from "@mantine/nprogress";
import { PubNubProvider } from "pubnub-react";
import { mypubnub } from "../config/pubnub";
import { ModalsProvider } from "@mantine/modals";

export default observer(function () {
  let [appState, setAppState] = React.useState("loaded");
  let checkAuthStatus;

  React.useEffect(() => {
    authManager.init();
  }, []);

  return (
    <React.Fragment>
      <PubNubProvider client={mypubnub}>
        <BrowserRouter>
          <MantineProvider
            theme={MaintineThemeConfig}
            withGlobalStyles
            withNormalizeCSS
          >
            <ModalsProvider>
              <NavigationProgress />
              <NotificationsProvider>
                <MainAppRoutes />
              </NotificationsProvider>
            </ModalsProvider>
          </MantineProvider>
        </BrowserRouter>
      </PubNubProvider>
    </React.Fragment>
  );
});
