import { authManager } from "@store/account/auth";
import { usePubNub } from "pubnub-react";
import { useEffect, useState } from "react";

export function useTicketChat(channel: string) {
  const pubnub = usePubNub();
  const [channels] = useState([channel]);
  const [messages, addMessage] = useState<any[]>([]);
  const [message, setMessage] = useState("");
  const handleMessage = (event: any) => {
    const message = event.message;
    let newMessageList = [...messages];
    newMessageList.push(message);
    //addMessage(newMessageList);
    addMessage((messages) => [...messages, message]);
  };

  const sendMessage = () => {
    pubnub
      .publish({
        channel: channels[0],
        storeInHistory: true,
        message: {
          text: message,
          user_id: authManager.user.id,
          created_on: new Date(),
        },
      })
      .then(() => setMessage(""));
  };

  useEffect(() => {
    const listenerParams = { message: handleMessage };
    pubnub.addListener(listenerParams);
    pubnub
      .history({
        channel: channel,
        count: 200,
      })
      .then((res: any) => {
        addMessage(
          res.messages.map((message: any) => {
            return message.entry;
          })
        );
      });
    pubnub.subscribe({ channels });
    return () => {
      pubnub.unsubscribe({ channels });
      pubnub.removeListener(listenerParams);
    };
  }, [pubnub, channels]);

  return {
    sendMessage,
    messages,
    setMessage,
    message,
  };
}
