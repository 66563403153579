import {
  Paper,
  Group,
  Center,
  Badge,
  Box,
  Text,
  Button,
  Tooltip,
} from "@mantine/core";
import { Ticket2 } from "iconsax-react";
import { useState, useEffect } from "react";
import { tripController } from "../../../config/sdk";
import { Reservation } from "../../../sdk/catalog";
import { formatCurrency } from "../../../utils";
import { PayforReservation } from "./PayforReservationButton";
import { Link, useParams } from "react-router-dom";
import { usePublicTrips } from "../../../hooks/manage-trip/use-public-trips";
import { MakeReservation } from "../processes/reservation/MakeReservationProcess";
import { RedoReservation } from "../processes/reservation/RedoReservationProcess";
const _ = require("lodash");

export function ReservationCard({ reservation }: { reservation: Reservation }) {
  let params = useParams();
  let [paymentObj, setPaymentObj] = useState();
  let [isAvailable, setIsAvailable] = useState<Boolean>();
  let { trip, driver, driverProfile } = usePublicTrips(
    reservation.trip_id ?? ""
  );

  function initPayment() {
    if (reservation.payment_status == "COMPLETED") {
      return;
    }

    tripController
      .tripControllerInitTripReservationPayment(reservation.trip_id, {
        seats: reservation.seats,
        reservation_id: reservation.id,
      })
      .then((e) => {
        //@ts-ignore
        setPaymentObj(e.data);
        setIsAvailable(true);
      })
      .catch((err) => {
        console.log(err.response);
        setIsAvailable(false);
      });
  }
  useEffect(() => {
    initPayment();
  }, []);

  if (reservation.trip_starts_at) {
    if (new Date(reservation.trip_starts_at) < new Date()) {
      return <Box></Box>;
    }
  }

  return (
    <Box style={{ textDecoration: "none", color: "black" }}>
      <Paper
        p="18px"
        sx={{ background: "ghostwhite", position: "relative" }}
        withBorder
      >
        <Badge
          style={{ position: "absolute", right: 0, bottom: 0, borderRadius: 0 }}
        >
          {reservation.payment_status == "COMPLETED" ? "Paid" : "Unpaid"}
        </Badge>
        <Group align="flex-start">
          <Box>
            <Ticket2 size={32} variant="Bulk" />
          </Box>
          <Box sx={{ flex: 1, background: "transparent" }}>
            <Text>
              {formatCurrency(
                reservation.price_per_seat * _.keys(reservation.seats).length
              )}{" "}
              ~ Fare
            </Text>
            <Text color="dimmed" size={"sm"}>
              {
                //@ts-ignore
                _.keys(reservation.seats).map((element) => {
                  return <span>Seat | {element} | </span>;
                })
              }
            </Text>
            {params.trip_id == undefined ? (
              <Link to={`/trips/${reservation.trip_id}`}>
                <Button variant="subtle" size="xs">
                  View trip
                </Button>
              </Link>
            ) : null}
          </Box>
          <div>
            <Group>
              {paymentObj == undefined ? (
                isAvailable == false &&
                trip != undefined &&
                driverProfile != undefined ? (
                  <RedoReservation
                    trip={trip}
                    driverProfile={driverProfile}
                    handle={<Button size="xs">Re-book</Button>}
                  />
                ) : null
              ) : (
                <PayforReservation
                  reservation={reservation}
                  paymentObj={paymentObj}
                  trip_id={reservation.trip_id}
                />
              )}
            </Group>
          </div>
        </Group>
      </Paper>
    </Box>
  );
}
