import {
  Paper,
  Stack,
  Group,
  Avatar,
  Button,
  Divider,
  Badge,
  Text,
  Box,
  Center,
} from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { bookingsManager } from "@store/bookings-manager";
import { HorizontalKeyValuePair } from "@ui/molecules/text";
import { Ticket2 } from "iconsax-react";
import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  tripController,
  userController,
  bookingController,
  driverController,
} from "../../../../../config/sdk";
import { Booking, Driver, Trip } from "../../../../../sdk/catalog";
import { APPMOMENT, friendlyLocationStr } from "../../../../../utils";
import { useConfirm } from "../../../../../hooks/use-confirm";
import { SeatBadge } from "@ui/organisms/vehicle-widgets/SeatBadget";
import { RenderBookingSelectedSeats } from "@ui/organisms/booking-widgets/RenderBookingSelectedSeats";
import { User } from "../../../../../sdk/auth";

const _ = require("lodash");

export function BookingCard({ booking }: { booking: Booking }) {
  let [trip, setTrip] = useState<Trip>();
  let [driver, setDriver] = useState<User>();
  let [driverProfile, setDriverProfile] = useState<Driver>();
  let { confirmGeneric } = useConfirm();

  const navigate = useNavigate();
  function load() {
    tripController.tripControllerGetById(booking.trip_id).then((e) => {
      setTrip(e.data);
    });
    userController
      .userControllerFindAccountById(booking.driver_id)
      .then((e) => {
        setDriver(e.data);
      });

    driverController
      .driverControllerGetDriverProfile(booking.driver_id)
      .then((e) => {
        setDriverProfile(e.data);
      });
  }
  useEffect(() => {
    load();
  }, []);

  return (
    <Box>
      <Paper
        p={"18px"}
        sx={(theme) => {
          return { width: "100%", background: "white" };
        }}
        shadow="md"
      >
        <Stack>
          <Box>
            <Group>
              <Ticket2 />
              <Group sx={{ flex: 1 }}>
                <Avatar src={driverProfile?.driver_kyc?.photo ?? ""} />
                <Box>
                  <Text>
                    {driver?.first_name} {driver?.last_name}
                  </Text>
                  <a href={`tel:${driver?.phone}`}>
                    <Text size={"xs"} color="dimmed">
                      {driver?.phone ?? ""}
                    </Text>
                  </a>
                </Box>
                <Box>
                  <Text size={"xs"} color="dimmed">
                    driver license number
                  </Text>
                  <Text>{driverProfile?.driver_kyc.driver_licence_number}</Text>
                </Box>
              </Group>
              {booking.status == "CANCELLED" ? null : (
                <Link to={`/trips/${booking.trip_id}`}>
                  <Button variant="white">View Trip</Button>
                </Link>
              )}
            </Group>
          </Box>
          <Divider />

          {trip == undefined ? null : (
            <React.Fragment>
              <HorizontalKeyValuePair
                label="Schedule"
                value={APPMOMENT(trip.trip_starts_at).format()}
              />
              <HorizontalKeyValuePair
                label="From"
                value={friendlyLocationStr(trip.start_location)}
              />
              <HorizontalKeyValuePair
                label="To"
                value={friendlyLocationStr(trip.end_location)}
              />
            </React.Fragment>
          )}

          <Group position="apart">
            <Text>Seats </Text>
            <RenderBookingSelectedSeats booking={booking} />
          </Group>
          <Divider />
          <Group position="apart">
            <Badge>{booking.status}</Badge>

            <Group>
              {booking.status == "PENDING" ? (
                <Button
                  color="red"
                  onClick={() => {
                    function callCancel() {
                      bookingController
                        .bookingControllerCancelTrip(booking.id)
                        .then((e) => {
                          bookingsManager.loadBookings();
                          showNotification({ message: "Cancelled trip" });
                        });
                    }

                    // pending
                    let timeNow= new Date();
                    let tripTime= new Date();
                    let gracePerioud = tripTime.getHours() - timeNow.getHours();
                    
                    if (new Date(trip?.trip_starts_at??"")) {
                      confirmGeneric(
                        "Confirm action",
                        `Are you sure you want to cancel this trip? You will be refunded 100% according to our cancellation policy`,
                        () => {
                          callCancel();
                        }
                      );
                    } else {
                      confirmGeneric(
                        "Confirm action",
                        `Are you sure you want to cancel this trip?  You will be refunded 50% according to our cancellation policy`,
                        () => {
                          callCancel();
                        }
                      );
                    }
                  }}
                >
                  Cancel
                </Button>
              ) : null}

              {booking.status == "PENDING" &&
              new Date() >= new Date(trip?.trip_starts_at ?? "0") ? (
                <Button
                  color="green"
                  onClick={() => {
                    confirmGeneric(
                      "Confirm action",
                      "Proceed to onboarding trip...",
                      () => {
                        bookingController
                          .bookingControllerOnboarded(booking.id)
                          .then((e) => {
                            bookingsManager.loadBookings();
                            showNotification({ message: "Onboarded vehile" });
                          });
                      }
                    );
                  }}
                >
                  Onboard trip
                </Button>
              ) : null}
              {booking.status == "ONBOARDED" ? (
                <Button
                  color="red"
                  onClick={() => {
                    confirmGeneric(
                      "Confirm action",
                      "Proceed to complete trip.",
                      () => {
                        bookingController
                          .bookingControllerCompleted(booking.id)
                          .then((e) => {
                            bookingsManager.loadBookings();
                            showNotification({ message: "Completed ride" });
                            window.open(`/trips/${booking.trip_id}`, "_parent");
                          });
                      }
                    );
                  }}
                >
                  Complete trip
                </Button>
              ) : null}
            </Group>
          </Group>
        </Stack>
      </Paper>
    </Box>
  );
}
