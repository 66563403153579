import {
  resetNavigationProgress,
  startNavigationProgress,
} from "@mantine/nprogress";
import { makeAutoObservable, runInAction } from "mobx";
import {  reservationController, reviewController } from "../config/sdk";
import { Booking, Reservation, Review } from "../sdk/catalog";

class ReservationManager {
  reservations: Reservation[] = [];
  
  constructor() {
    makeAutoObservable(this);
  }
  
  async loadReservations() {
    try {
      let response = await reservationController.reservationControllerGet();
      runInAction(() => {
        this.reservations = response.data;
      });
    } catch (err) {
      console.log(err);
    }
  }
}

export const reservationManager = new ReservationManager();
