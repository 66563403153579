import {
  Header,
  MediaQuery,
  Burger,
  useMantineTheme,
  Text,
  Box,
  Stack,
  Title,
  Group,
  Input,
  Avatar,
  Container,
  Divider,
  Grid,
  TextInput,
  Button,
  ActionIcon,
} from "@mantine/core";
import { IconBrandFacebook, IconBrandInstagram, IconBrandLinkedin, IconBrandMedium, IconBrandTwitter } from "@tabler/icons";
import { Facebook, Instagram } from "iconsax-react";
import { Link } from "react-router-dom";


const AppFooter: React.FC<{}> = ({ }) => {
  let theme = useMantineTheme();

  function GetLinks({ links }: { links: { link: string, label: string }[] }) {
    return <Stack>
      {links.map((element, index) => {
        return (
          <a
          key={index}
            style={{ textDecoration: "none", color: "white" }}
            href={element.link}
          >
            <Text>{element.label}</Text>
          </a>
        );
      })}
    </Stack>
  }
  return (
    <Box
      sx={(theme) => {
        return { background: "black", color: "ghostwhite" };
      }}
    >
      <Container size={"lg"} sx={{ padding: "42px 0px" }}>
        <Grid sx={{ padding: 0, margin: 0 }} justify="space-between">
          <Grid.Col md={5}>
            <Stack>
              <Text sx={{ fontWeight: "bold" }}>
              <img src={require("@ui/png/logo.png")} width={120}/>
              </Text>
              <Text sx={{}}>
                Experience convenience with Etoju.
              </Text>
              <Group sx={{ fontSize: 24 }}>
                <a href={"https://www.facebook.com/profile.php?id=100091921293198"}>
                  <ActionIcon size={40}>
                    <IconBrandFacebook size={40} />
                  </ActionIcon></a>
                <a href={"https://twitter.com/ridealong_ng"}>
                  <ActionIcon size={40}>
                    <IconBrandTwitter size={40} />
                  </ActionIcon>
                </a>
                <a href={"https://www.instagram.com/ridealong.ng/"}>
                  <ActionIcon size={40}>
                    <IconBrandInstagram size={40} />
                  </ActionIcon>
                </a>
                <a href={"https://medium.com/@ridealongsuccess"}>
                  <ActionIcon size={40}>
                    <IconBrandMedium size={40} />
                  </ActionIcon>
                </a>


              </Group>
            </Stack>
          </Grid.Col>

          <Grid.Col md={6}>
            <Grid sx={{ padding: 0, margin: 0 }}>
              <Grid.Col xs={6} sm={6} md={4} style={{ marginBottom: 24 }}>
                <Stack align={"stretch"}>
                  <Text sx={{ fontWeight: "bold" }}>Company</Text>
                  <GetLinks links={[
                    {
                      label: "Explore routes",
                      link: "/trips",
                    },
                    {
                      label: "Get started",
                      link: "/getstarted",
                    },
                    {
                      label: "Login",
                      link: "/signin",
                    },
                  ]} />
                </Stack>
              </Grid.Col>
              <Grid.Col xs={6} sm={6} md={4} style={{ marginBottom: 24 }}>
                <Stack align={"stretch"}>
                  <Text sx={{ fontWeight: "bold" }}>Important</Text>
                  <GetLinks links={[
                    {
                      label: "About",
                      link: "/about",
                    },
                    {
                      label: "How to's",
                      link: "/how-tos",
                    },
                    {
                      label: "FAQs",
                      link: "/faqs",
                    },
                  ]} />
                </Stack>
              </Grid.Col>
              <Grid.Col md={4} style={{ marginBottom: 24 }}>
                <Stack>
                  <Text sx={{ fontWeight: "bold" }}>Legal</Text>
                  <GetLinks links={[
                    {
                      label: "Terms of Service",
                      link: "t-and-c",
                    },
                    {
                      label: "Privacy Policy",
                      link: "privacy-policy",
                    },
                    {
                      label: "Refund policy",
                      link: "refund-policy",
                    },
                  ]} />
                </Stack>
              </Grid.Col>
            </Grid>
          </Grid.Col>
        </Grid>
      </Container>
      <Container size={"lg"}>
        <Text sx={{ textAlign: "center", padding: "24px 0px" }}>
          All Rights Reserved © 2023
        </Text>
      </Container>
    </Box>
  );
};

export default AppFooter;
