import {
  Box,
  Button,
  Center,
  Group,
  Indicator,
  Stack,
  Text,
} from "@mantine/core";
import { observer } from "mobx-react";
import { Driver, Trip } from "../../../../../sdk/catalog";
import { User } from "iconsax-react";
import { SeatBadge } from "@ui/organisms/vehicle-widgets/SeatBadget";
import { useTripUtilsHook } from "../../../../../hooks/user-hook";

export const RenderSelectableSSeats = observer(
  ({
    driverProfile,
    trip,
    selectAllSeat,
    selectSeat,
    reserveSeats,
  }: {
    driverProfile: Driver;
    trip: Trip;
    selectAllSeat: (seats: number[]) => void;
    selectSeat: (seat_number: number) => void;
    reserveSeats: number[];
  }) => {
    let { vehicle_seats } = useTripUtilsHook(driverProfile, trip);

    if (vehicle_seats.length == 0) {
      return <Text>All seats have been taken</Text>;
    }

    return (
      <Box>
        <Stack style={{ height: "100%" }} align="center" justify={"center"}>
          <Group position="center">
            {vehicle_seats.map((_) => {
              //@ts-ignore
              if (_.is_available == false) {
                return <Box></Box>;
                return <SeatBadge selected={true} />;
              }

              return (
                <Stack
                  align="center"
                  spacing={0}
                  onClick={() => {
                    selectSeat(_.index);
                  }}
                >
                  <SeatBadge
                    selectedColor="orange"
                    selected={
                      reserveSeats.find(
                        (seat_index) => seat_index == _.index
                      ) == undefined
                        ? false
                        : true
                    }
                  />
                  {_.index}
                </Stack>
              );
            })}
          </Group>
          {/* {Object.keys(trip.seats ?? {}).length == 0 ? (
            <Button
              variant="subtle"
              color="green"
              size="sm"
              onClick={() => {
                // selectAllSeat(seats);
              }}
            >
              Select all
            </Button>
          ) : null} */}
        </Stack>
      </Box>
    );
  }
);
