import {
  Box,
  Container,
  Grid,
  Stack,
  Title,
  Text,
  Button,
  Center,
  Paper,
  useMantineTheme,
  MediaQuery,
} from "@mantine/core";
import { observer } from "mobx-react";
import ridejpg from "../../png/ride.png";
import getstartedjpg from "../../png/woman.jpg";

import { Like, Money, ShieldTick } from "iconsax-react";
import React from "react";
import { FancySectionCover } from "./component/FancySectionCover";
import { RenderPlatformBanner, RenderPlatformFancyBanner } from "@ui/organisms/utils/covers";
import { Link } from "react-router-dom";
import driverpassengerride from "@ui/png/driverpassengerride.jpg"
import { useUi } from "../../../hooks/use-ui";
import { useWindowScroll } from "@mantine/hooks";
export default observer(function GetstartedPage() {
  const [scroll, scrollTo] = useWindowScroll();
  const theme = useMantineTheme();

  useUi()
  return (
    <Box>
      <RenderPlatformFancyBanner
        title="Get started"
        cover={driverpassengerride}
        caption="Carpooling between cities is easy, safe and convenient."
      />
      
      <Container size={"md"} my="100px">
        <Stack>
          <Grid justify={"center"} align="stretch">
            <ActionCard
              title="For Passengers"
              caption="Find a ride, book and go."
              ActionBution={
                <Link to={"/signup"}>
                  <Button sx={{ textAlign: "center" }} size="lg">
                    Signup
                  </Button></Link>
              }
            />
            <ActionCard
              title="For drivers"
              caption="Fill your seats with friendly 
passangers to cover your cost"
              ActionBution={
                <Link to={"/driver-signup"}>
                  <Button
                    sx={{ textAlign: "center" }}
                    size="lg"
                    color={"appdark"}
                  >
                    Signup
                  </Button>
                </Link>
              }
            />
          </Grid>
        </Stack>
      </Container>



      <Container size={"md"} my="100px">
        <Stack>
          <Grid my="42px" justify={"center"}>
            <Grid.Col md={5}>
              <Center>
                <ShieldTick
                  size={100}
                  color={theme.colors.orange[5]}
                  variant="Bold"
                />
              </Center>
            </Grid.Col>
            <Grid.Col md={5}>
              <Text>
                Safety and security are our top priorities. We are committed to
                providing a safe and reliable platform for both drivers and
                passengers, and we take payment security very seriously.
              </Text>
            </Grid.Col>
          </Grid>

          <FancySectionCover cover={getstartedjpg}>
            <Stack
              p={"32px"}
              sx={{ backgroundImage: "linear-gradient(transparent, white)" }}
            >
              <Center>
                <Like
                  size={100}
                  color={theme.colors.orange[5]}
                  variant="Bold"
                />
              </Center>
              <Title sx={{ textAlign: "center" }}>
                We'd love to have you travel with us!
              </Title>

              <Center>
                <Button size="lg" onClick={() => scrollTo({ y: 0 })}>Get Started</Button>
              </Center>
            </Stack>
          </FancySectionCover>
        </Stack>
      </Container>
    </Box>
  );
});

function ActionCard({
  caption,
  title,
  ActionBution,
}: {
  title: string;
  caption: string;
  ActionBution: React.ReactNode;
}) {
  return (
    <Grid.Col md={6}>
      <Paper
        withBorder
        p="md"
        sx={{ textAlign: "center", height: "100%" }}
        py="38px"
      >
        <Stack justify={"space-between"} style={{ height: "100%" }}>
          <Box>
            <Title sx={{ fontSize: 24 }}>{title}</Title>
            <Text>{caption}</Text>
          </Box>
          <Center>{ActionBution}</Center>
        </Stack>
      </Paper>
    </Grid.Col>
  );
}
