import { openConfirmModal } from "@mantine/modals";
import { Text } from "@mantine/core";

export function useAlert() {
  function alertGeneric(title: string, body: string, action: () => void) {
    openConfirmModal({
      title: title,
      children: <Text size="sm">{body}</Text>,
      labels: { confirm: "Next", cancel: "Cancel" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => action(),
    });
  }

  
  return { alertGeneric };
}
