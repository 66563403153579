import {
  Box,
  Button,
  Center,
  Group,
  Paper,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import {
  DirectboxSend,
  ExportSquare,
  SearchNormal1,
  Ticket,
} from "iconsax-react";
import { Link, useNavigate } from "react-router-dom";
import { MyReservations } from "./MyReservations";
import { bookingsManager } from "@store/bookings-manager";
import { BookingCard } from "@ui/pages/account/bookings/components/BookingCard";
import React from "react";
import { observer } from "mobx-react";

export const PassengerActivities = observer(() => {
  const navigation = useNavigate();

  let currentBooking = bookingsManager.bookings.find(
    (_) => _.status == "PENDING" || _.status=="ONBOARDED"
  );

  return (
    <Stack>
      {currentBooking == undefined ? null : (
        <React.Fragment>
          <Text>Outstanding trip</Text>
          <BookingCard booking={currentBooking} />
        </React.Fragment>
      )}

      <Text>Activities</Text>
      <Paper
        p={"18px"}
        sx={(theme) => {
          return {
            width: "100%",
            background: "white",
            borderTop: "5px solid gray",
          };
        }}
        shadow="md"
      >
        <Stack>
          <Link
            to={"/account/find-trips"}
            style={{ textDecoration: "none", color: "black" }}
          >
            <Paper p="18px" sx={{}} withBorder>
              <Group align="center">
                <Center>
                  <Ticket size={32} variant="Bulk" />
                </Center>
                <Text sx={{ flex: 1 }}>
                Search for Trips
                </Text>
                <ExportSquare />
              </Group>
            </Paper>
          </Link>

          <Link to={"#"} style={{ textDecoration: "none", color: "black" }}>
            <Paper p="18px" sx={{ background: "ghostwhite" }} withBorder>
              <Group align="center">
                <Center>
                  <DirectboxSend size={32} variant="Bulk" />
                </Center>
                <Box sx={{ flex: 1 }}>
                  <Text>Publish trip request</Text>
                  <Text color="dimmed" size={"sm"}>
                    Coming soon
                  </Text>
                </Box>
                <ExportSquare />
              </Group>
            </Paper>
          </Link>
        </Stack>
      </Paper>

      <MyReservations />
    </Stack>
  );
});
