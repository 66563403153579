import { useEffect, useState } from "react";
import { LoginDtoAccountTypeEnum, User } from "../sdk/auth";
import { driverController, userController } from "../config/sdk";
import {
  Driver,
  PublishTripDto,
  Trip,
  UpdateAccountStatusAccountStatusEnum,
} from "../sdk/catalog";
import {
  resetNavigationProgress,
  startNavigationProgress,
} from "@mantine/nprogress";
import { showNotification } from "@mantine/notifications";

export function useUserHook(user_id: string) {
  let [userProfile, setUserProfile] = useState<User>();
  function loadUserProfile() {
    userController.userControllerFindAccountById(user_id).then((response) => {
      setUserProfile(response.data);
    });
  }
  useEffect(() => {
    loadUserProfile();
  }, []);

  return {
    userProfile,
    loadUserProfile,
  };
}

export function useTripUtilsHook(
  driverProfile: Driver,
  trip: { seats: Object }
) {
  let _vehicle_seats: number[] = [];
  let seats_count = parseInt(driverProfile.vehicle_seats ?? "0");
  for (let count = 0; count < seats_count; count++) {
    //  make vehilce seats into array
    _vehicle_seats.push(count + 1);
  }

  // update seats to include availability
  let vehicle_seats = _vehicle_seats.map((_) => {
    return {
      index: _,
      is_available:
        Object.keys(trip.seats ?? {}).find(
          (secured_seat_index) => parseInt(secured_seat_index) == _
        ) == undefined
          ? true
          : false,
    };
  });
  return { vehicle_seats };
}
