import { authManager } from "@store/account/auth";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export function useUi() {
  let navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
}

export function useCheckVerification() {
  let navigate = useNavigate();
  useEffect(() => {
    authManager.loadProfile().then((_) => {
      if (authManager.status == "AUTHENTICATED") {
        if (!authManager.user.email_activated) {
          navigate("/verify");
        }
      } else {
        navigate("/");
      }
    });
  }, []);
}
