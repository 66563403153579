import {
  Grid,
  Text,
  Paper,
  Stack,
  useMantineTheme,
  Container,
} from "@mantine/core";
import { Link, useNavigate } from "react-router-dom";
import { ProfileSummaryCard } from "./components/ProfileSummaryCard";
import { PassengerActivities } from "./components/PassengerActivities";
import { MyReservations } from "./components/MyReservations";
import { useUi } from "../../../hooks/use-ui";

export const DashboardScreen = () => {
  useUi();
  let theme = useMantineTheme();
  let navigate = useNavigate();
  return (
    <Container p="md" size={"lg"}>
      <Grid>
        <Grid.Col md={4}>
          <Stack>
            <Text>My Profile</Text>
            <ProfileSummaryCard />
          </Stack>
        </Grid.Col>
        <Grid.Col md={8}>
          <PassengerActivities />
        </Grid.Col>
      </Grid>
    </Container>
  );
};
