import { useState } from "react";
import { reservationController, tripController } from "../../config/sdk";
import { Reservation, Trip } from "../../sdk/catalog";
import { showNotification } from "@mantine/notifications";
import { reservationManager } from "@store/my-reservations";
import { authManager } from "@store/account/auth";

export function useMakeReservation({ trip }: { trip: Trip }) {
  let [paymentObj, setPaymentObj] = useState();

  let [reservationSent, setReservationSent] = useState(false);
  let [reservation, setReservation] = useState<Reservation>();
  let [reserveSeats, setReserveSeats] = useState<number[]>([]);

  function clear() {
    setReservationSent(false);
    setReserveSeats([]);
  }

  async function checkTripAvailability(seats: Object) {
    try {
      let response = await tripController.tripControllerCanAcceptReservation(
        trip.id,
        { seats: seats }
      );
      return response.data;
    } catch (e) {
      return false;
    }
  }

  function selectSeat(seat_number: number) {
    try {
      if (reserveSeats.find((_) => _ == seat_number)) {
        // remove from list
        setReserveSeats(reserveSeats.filter((_) => _ != seat_number));
      } else {
        const new_list = [...reserveSeats, seat_number];
        setReserveSeats(new_list);
      }
    } catch (e) {
      return false;
    }
  }

  function selectAllSeat(seats: number[]) {
    setReserveSeats(seats);
  }

  async function makeReservation() {
    if (reserveSeats.length == 0) {
      showNotification({
        message: "Select atleast one seat to make reservation",
      });
      return;
    }

    let seats_obj: { [key: number]: Object } = {};

    reserveSeats.forEach((_) => {
      seats_obj[_] = { user_id: authManager.user.id };
    });

    let check = await checkTripAvailability(seats_obj);

    if (check) {
      try {
        let response = await reservationController.reservationControllerPublish(
          {
            //@ts-ignore
            driver_id: trip.driver_id,
            price_per_seat: trip.trip_spec.price_per_seat,
            seats: seats_obj,
            trip_id: trip.id,
            trip_starts_at: trip.trip_starts_at
          }
        );

        // request payment object if auto accept is set to true.
        if (trip.trip_spec.auto_accept_reservation == true) {
          setReservation(response.data);
          tripController
            .tripControllerInitTripReservationPayment(trip.id, {
              seats: response.data.seats,
              reservation_id: response.data.id,
            })
            .then((e) => {
              console.log(e.data, "loaded payment object");
              //@ts-ignore
              setPaymentObj(e.data);
            });
        }

        reservationManager.loadReservations();

        setReservationSent(true);
      } catch (e) {
        showNotification({
          message: "We could not complete the request, please try again",
        });
      }
    } else {
      showNotification({
        message: "You can't make reservations at this moment",
      });
    }
  }

  return {
    selectSeat,
    reserveSeats,
    makeReservation,
    reservationSent,
    clear,
    selectAllSeat,
    paymentObj,
    reservation
  };
}
