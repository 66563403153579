import { Box, Container, Stack } from "@mantine/core";
import { SectionHeader } from "@ui/organisms/common-widgets/SectionHeader";
import { RenderPlatformBanner, RenderPlatformFancyBanner } from "@ui/organisms/utils/covers";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { useUi } from "../../../hooks/use-ui";

export const AboutScreen = () => {
  useUi()
  return (
    <Box>
      <RenderPlatformFancyBanner title="About Etoju" />
      <Container>
        <ReactMarkdown children={`Etoju is a ride-sharing platform that connects drivers with passengers going in the same direction. Our platform provides an affordable and convenient way to travel while reducing traffic congestion and emissions.

## **Our Mission**

At Etoju, our mission is to revolutionize transportation by providing an affordable, efficient, and sustainable solution for commuters. We aim to make commuting hassle-free and enjoyable while reducing the environmental impact of transportation.

## **Our Values**

Safety: We prioritize the safety of our users and make every effort to ensure a secure and comfortable ride.

Sustainability: We are committed to reducing emissions and promoting sustainable transportation.

Convenience: We strive to make commuting as easy and convenient as possible for our users.

Affordability: We believe that everyone should have access to affordable transportation.

## **Our Story**

Etoju was founded in 2019 by a group of transportation experts who were passionate about improving the way people commute. They saw the potential for ride-sharing to reduce traffic congestion and emissions, and wanted to make it accessible to everyone.

Since then, Etoju has grown into a community of drivers and passengers who are committed to making transportation more affordable, efficient, and sustainable. We are constantly working to improve our platform and expand our network to make commuting easier and more enjoyable for everyone.

Thank you for choosing Etoju as your preferred ride-sharing platform. We look forward to serving you!`} />
      </Container>
    </Box>
  );
};
