import { LinksGroupProps } from "@interface/models";
import {} from "@tabler/icons";
import {
  Activity,
  Receipt1,
  Setting3,
  ArrowSquareRight,
  Wallet1,
  Ticket2,
} from "iconsax-react";

export const appNavigationLinks: LinksGroupProps[] = [
  { label: "Dashboard", icon: Activity, link: "/account" },
  
  { label: "Bookings", icon: Receipt1, link: "/account/bookings" },
  { label: "Tickets", icon: Ticket2, link: "/account/tickets" },
  { label: "Settings", icon: Setting3, link: "/account/settings" },
];

export const appDriverNavigationLinks: LinksGroupProps[] = [
  { label: "Dashboard", icon: Activity, link: "/account/driver" },
  { label: "Trips", icon: ArrowSquareRight, link: "/account/driver/trips" },
  { label: "Payout", icon: Wallet1, link: "/account/driver/payouts" },
  { label: "Tickets", icon: Ticket2, link: "/account/driver/tickets" },
  { label: "Settings", icon: Setting3, link: "/account/driver/settings" },
];

export const appPublicNavigationLinks = [
  {
    label: "Explore routes",
    path: "/trips",
    link: "/trips",
    useNativeRedirect: true,
  },
  {
    label: "About",
    path: "/about",
    link: "/about",
    useNativeRedirect: true,
  },
  {
    label: "How it works",
    path: "/how-it-works",
    link: "/how-it-works",
    useNativeRedirect: true,
  },
  {
    label: "FAQs",
    path: "/faqs",
    link: "/faqs",
    useNativeRedirect: true,
  },
];

export const appPublicMobileNavigationLinks = [
  ...appPublicNavigationLinks,
  {
    label: "Signin",
    link: "/signin",
    useNativeRedirect: true,
  },
  {
    label: "Get started",
    link: "/getstarted",
    useNativeRedirect: true,
  },
];
