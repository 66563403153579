import {
  Avatar,
  Box,
  Grid,
  Group,
  Paper,
  Stack,
  Title,
  Text,
  Button,
  Container,
} from "@mantine/core";
import { ticketsManager } from "@store/tickets";
import { VerticalKeyValuePair } from "@ui/molecules/text";
import { observer } from "mobx-react";
import { useEffect } from "react";
import { useUserHook } from "../../../hooks/user-hook";
import { Ticket } from "../../../sdk/catalog";
import { Link } from "react-router-dom";
import { PostTicketProcess } from "@ui/organisms/processes/post-ticket/PostTicketProcess";
import { useUi } from "../../../hooks/use-ui";
import { ArrowRight } from "iconsax-react";

export default observer(function TicketsScreen() {
  useUi();
  useEffect(() => {
    ticketsManager.loadItems();
  }, []);
  return (
    <Box style={{ overflow: "hidden !important" }} mt="xl">
      <Container size={"xl"}>
        <Stack>
          <Grid>
            <Grid.Col md={8}>
              <Group position="apart" align="center">
                <Title sx={{ fontSize: 24 }}>Support Tickets</Title>
                <PostTicketProcess />
              </Group>
            </Grid.Col>
          </Grid>
          <Grid align="stretch">
            <Grid.Col md={8}>
              <Paper
                p={"18px"}
                sx={(theme) => {
                  return { width: "100%", height: "100%" };
                }}
                shadow="md"
              >
                <Stack>
                  <Grid align="stretch">
                    {ticketsManager.items.map((e) => {
                      return (
                        <Grid.Col md={6}>
                          <RenderTicketCard ticket={e} />
                        </Grid.Col>
                      );
                    })}
                  </Grid>
                </Stack>
              </Paper>
            </Grid.Col>
            <Grid.Col md={4}>
              <Paper
                p={"18px"}
                sx={(theme) => {
                  return { width: "100%" };
                }}
                shadow="md"
              >
                <Stack>
                  <Text>FAQs</Text>
                  <Stack>
                    {[
                      { label: "Frequently asked questions", link: "/faqs" },
                      { label: "About Etoju", link: "/about" },
                      { label: "How it works", link: "/how-tos" },
                      { label: "Refund policy", link: "/refund-policy" },
                      { label: "Terms & conditions", link: "/t-and-c" },
                    ].map((e) => {
                      return (
                        <a href={e.link}>
                          <Paper p="12px" withBorder>
                            <Group position="apart">
                              <Text>{e.label}</Text>
                              <ArrowRight />
                            </Group>
                          </Paper>
                        </a>
                      );
                    })}
                  </Stack>
                </Stack>
              </Paper>
            </Grid.Col>
          </Grid>
        </Stack>
      </Container>
    </Box>
  );
});

function RenderTicketCard({ ticket }: { ticket: Ticket }) {
  let { userProfile } = useUserHook(ticket.publisher_id);
  return (
    <Paper p={"12px"} withBorder sx={{ height: "100%" }}>
      <Stack sx={{ flex: 1, height: "100%" }} justify="space-between">
        <Stack>
          <Group position="apart">
            <Group>
              <Avatar src={userProfile?.profile_photo} size={"sm"} />
              <Text size={"sm"} color="dimmed">
                {userProfile?.first_name} {userProfile?.last_name}
              </Text>
            </Group>
            <Text>{ticket.status}</Text>
          </Group>
          <Box>
            <Text>{ticket.subject ?? "No subject"}</Text>
            <Text color="dimmed">{ticket.issue}</Text>
          </Box>
        </Stack>
        <Group position="right">
          <Link to={`${ticket.id}`}>
            <Button variant="subtle" size="sm" onClick={() => {}}>
              View
            </Button>
          </Link>
        </Group>
      </Stack>
    </Paper>
  );
}
