import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Center,
  Group,
  Paper,
  Stack,
  Text,
} from "@mantine/core";
import { reservationManager } from "@store/my-reservations";
import { Ticket2, Trash } from "iconsax-react";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { formatCurrency } from "../../../../utils";
import { ReservationCard } from "@ui/organisms/reservation-widgets/ReservationCard";
const _ = require("lodash");

export const MyReservations = observer(() => {
  useEffect(() => {
    reservationManager.loadReservations();
  }, []);

  return (
    <Stack>
      <Text>My Reservations</Text>
      <Paper
        p={"18px"}
        sx={(theme) => {
          return {
            width: "100%",
            background: "white",
            borderTop: "5px solid purple",
          };
        }}
        shadow="md"
      >
        <Stack>
          {reservationManager.reservations.map((e) => {
            if (e.payment_status == "COMPLETED") {
              return <div></div>;
            }
            return <ReservationCard reservation={e} />;
          })}
        </Stack>
      </Paper>
    </Stack>
  );
});
