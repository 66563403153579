import { Box, Button, Center, Container, Grid, Paper, Stack, Text, TextInput, Title } from "@mantine/core";
import { SectionHeader } from "@ui/organisms/common-widgets/SectionHeader";
import { RenderPlatformBanner } from "@ui/organisms/utils/covers";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import { authController } from "../../../config/sdk";
import { ReceiptEdit, Verify } from "iconsax-react";
import { showNotification } from "@mantine/notifications";
import { authManager } from "@store/account/auth";
import { useForm } from "@mantine/form";
import { ResetPasswordDto } from "../../../sdk/auth";
import { useUi } from "../../../hooks/use-ui";

export default observer(() => {
  useUi()
  
  let { passwordResetToken } = useParams();
  let form = useForm<ResetPasswordDto>({
    initialValues: {
      email: "",
      password: "",
      passwordResetToken: passwordResetToken ?? ""
    }
  })

  return (
    <Box>
      <RenderPlatformBanner title="Reset password" caption="" />
      <Container>
        <Grid justify="center">
          <Grid.Col md={10}>
            <form>
              <Stack>
                <Center>
                  <ReceiptEdit color="gray" size={100} variant="Bulk" />
                </Center>
                <Title></Title>
                <TextInput label="Email" {...form.getInputProps("email")} />
                <TextInput label="New Password"  {...form.getInputProps("password")} />

                <Button color="red" size="lg" onClick={() => {
                  authController.authControllerResetPassword({
                    ...form.values
                  }).then(res => {
                    showNotification({ message: "Password has being updated successfully" });
                  }).catch(err => {
                    showNotification({ message: "Could not complete reset process." });
                    console.log(err.response)
                  });
                }}>Update passsword</Button>
              </Stack>
            </form>
          </Grid.Col>
        </Grid>
      </Container>
    </Box>
  );
})
