import {
  Header,
  MediaQuery,
  Burger,
  useMantineTheme,
  Text,
  Box,
  Group,
  Button,
  Container,
  Title,
} from "@mantine/core";
import { authManager } from "@store/account/auth";
import { User } from "iconsax-react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { appPublicNavigationLinks } from "../../../config/link";

const MarketplaceHeader: React.FC<{
  setOpened: (data: any) => void;
  opened: boolean;
}> = ({ opened, setOpened }) => {
  let theme = useMantineTheme();
  return (
    <Header
      height={80}
      sx={(theme) => {
        return {
          borderBottom: "0px solid rgba(200,200,200,.5)",
          height: 80,
          // position: "absolute",
          background: "black"
        };
      }}
    >
      <Container size={"lg"} sx={{ height: "100%" }}>
        <Group
          style={{ display: "flex", height: "100%" }}
          align="center"
          position="apart"
        >
          <Box
            sx={(theme) => {
              return {
                height: "100%",
                display: "flex",
                alignContent: "center",
                alignItems: "center",
              };
            }}
          >
            <Group>
              <Link to={"/"} style={{ textDecoration: "none", color: "orange" }}>
                   <img src={require("@ui/png/logo.png")} width={100}/>
              </Link>
            </Group>
          </Box>

          <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
            <Box>
              <Group position="center">
                {appPublicNavigationLinks.map((element, index) => {
                  return (
                    <a
                    key={index}
                      href={element.path}
                      style={{ textDecoration: "none", color: "ghostwhite" }}
                    >
                      <Text>{element.label}</Text>
                    </a>
                  );
                })}
              </Group>
            </Box>
          </MediaQuery>

          {authManager?.user?.id == undefined ? (
            <Box>
              <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
                <Group align={"center"}>
                  <Link to={"/signin"}>
                    <Button variant="light">Sign In</Button>
                  </Link>
                  <Link to={"/getstarted"}>
                    <Button>Get Started</Button>
                  </Link>
                </Group>
              </MediaQuery>

              <MediaQuery largerThan="sm" styles={{ display: "none" }}>
                <Burger
                  opened={!opened}
                  onClick={() => setOpened((o: any) => !o)}
                  size="sm"
                  color={theme.colors.gray[6]}
                />
              </MediaQuery>
            </Box>
          ) : (
            <Group align={"center"}>
              <Link to={authManager.user.account_type == "DRIVER"?"/account/driver":"/account"}>
                <Button >Account</Button>
              </Link>
              <MediaQuery largerThan="sm" styles={{ display: "none" }}>
                <Burger
                  opened={!opened}
                  onClick={() => setOpened((o: any) => !o)}
                  size="sm"
                  color={theme.colors.gray[6]}
                />
              </MediaQuery>
            </Group>
          )}
        </Group>
      </Container>
    </Header>
  );
};

export default observer(MarketplaceHeader);
