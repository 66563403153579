import { Box, Center, Group } from "@mantine/core";
import { Booking } from "../../../sdk/catalog";
import { SeatBadge } from "../vehicle-widgets/SeatBadget";

export const RenderBookingSelectedSeats = ({ booking }: { booking: Booking }) => {
  return (
    <Box>
      <Group>
        {Object.keys(booking.seats).map((_, index) => {
          return (
            <Box key={index}>
              <SeatBadge
                selected={true}
                selectedColor="green"
                unselectedColor="gray"
              />
              <Center>{_}</Center>
            </Box>
          );
        })}
      </Group>
    </Box>
  );
};
