import * as React from "react"
import { SVGProps } from "react"
const VehicleSeatSvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={1146.667}
    height={1306.667}
  
    viewBox="0 0 860 980"
    {...props}
   style={{color:"red"}}
  >
    <path d="M336 .9c-14.7.7-16 1.3-26.1 11.8-11 11.5-16.3 20.6-22.4 38.9-4.6 13.7-8 28.2-9.6 40.2-1.5 12-.3 18.6 4.4 23.2 5.3 5.2 10.7 6.2 34.5 6.7l21.2.5V153h-21.7c-45.2 0-60.9 4.5-80.9 23.2-8.6 8.1-13.9 16.3-24.3 38C165.9 308 144 391.2 132 514c-5.5 57.4-7.4 95.7-7.4 155.5-.1 50.8-.1 51 2.2 58.5 9.8 31 34.4 55.4 66.1 65.4 10.4 3.3 17 3.5 25.4.7 10.5-3.5 10.4-3.3 6.3-12.9-7.6-17.7-7.7-18.5-8.6-52.7-2.7-109.7 9.1-231.1 30.6-314.5 7.9-30.7 24.1-76.1 35.6-99.7 5.8-11.9 13.2-20.7 23.5-27.7 8.8-5.9 15.6-8.2 28.2-9.5 14.1-1.5 177.1-1.5 191.5 0 13.1 1.3 19.7 3.5 28.6 9.4 16.5 10.9 26.5 28.4 42.5 74.1 19.9 56.7 33 117.2 40 184.4 4.4 43.1 6.2 82.7 7.1 159.7l.6 55.1-5.6 13.5c-3.1 7.4-5.6 14.3-5.6 15.4 0 2.9 8.7 6.5 17 7.1 4.8.3 8.7-.1 14.4-1.7 26.1-7.2 49.2-25.9 62.1-50.4 8.5-16.2 8.8-17.6 9.3-56.7.7-56.4-3.3-134.1-9.8-193-12.1-107.9-33.5-187.1-73.7-272.5-14.9-31.8-21.6-41.5-35.6-52.1-16.7-12.7-33.2-16.4-72.9-16.4H522v-15c0-14.7 0-15 2.3-15.4 1.2-.3 11-.7 21.7-1 10.7-.3 21.2-1.1 23.2-1.6 4.6-1.3 9.3-5.2 11.5-9.4 3.6-6.9.5-31.2-7.3-55.5-8.2-26-18.1-41.2-33.5-51.1l-6.2-4-92.6.2c-50.9.1-98.2.4-105.1.7zm155 136.6V153H369v-31h122v15.5z" />
    <path d="M361 307.1c-14.6.4-28.3 1.2-30.5 1.8-14.2 3.8-20.9 14.3-36 56.1-19.1 52.9-31.8 110.3-39.4 178.5-7.5 66.4-11.4 186.8-7 211.2 1.4 7.4 7.9 21.5 11 23.6 3.4 2.4 10.2 2.1 27.4-1.3 92.2-18.1 186.1-18 283.5.2 22.4 4.1 27.6 4.3 31.1 1.2 1.1-1 3.8-5.4 6-9.9 5.2-10.6 5.9-16.9 5.9-56 0-156.1-16.6-269-53.1-362-9.8-25-16.6-35.2-26.4-40-5.2-2.6-6.4-2.7-28.2-3.6-26.6-1-106.2-.9-144.3.2z" />
    <path d="M22.9 737.6c-6.7 2-14.1 9.1-18.1 17.2-3.2 6.4-3.3 7.1-3.6 21.2-.8 31.6 6.7 76.8 17.3 105.6 5.3 14.3 14.4 32.4 21.6 43.4 8.4 12.6 26.6 30.9 38.1 38.4 9.3 6.1 15.6 9.1 28.3 13.7l8 2.9 317-.2 317-.3 11.1-4.5c17-7 26.9-13.6 40.9-27.5 12.6-12.5 20.4-22.9 28.8-38.5 17.4-32.3 27.7-76 29.4-123.6.6-16.7.4-18.6-1.6-25.2-7.1-23.4-30.4-30.6-56.6-17.6-12.2 6-13.7 7.7-23 26.8-9.9 20.3-18.2 33.2-27.8 43.3-16 16.8-40.6 28.2-60.8 28.3-10.7 0-31.5-1.9-44.8-4.1-14.4-2.4-37.3-8.1-69.1-17.2-37.6-10.8-66-16.7-96.8-20.4-21-2.5-75.8-2.5-96.2 0-33.4 4.1-58.2 9.3-102 21.8-47.2 13.4-64.1 16.9-88.4 18.9-18.8 1.5-27.3 1.3-35.8-.9-24.3-6.2-43.7-20.5-58.7-43.2-3.5-5.2-10.1-17.2-14.7-26.7-9.4-19-10.2-20-23.5-26.7-12.3-6.3-25.8-8.1-36-4.9z" />
  </svg>
)
export default VehicleSeatSvgComponent
