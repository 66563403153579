import {
  Box,
  Button,
  Center,
  Modal,
  Stack,
  TextInput,
  Text,
  Group,
  Grid,
  Title,
  Textarea,
} from "@mantine/core";
import {
  HorizontalKeyValuePair,
  VerticalKeyValuePair,
} from "@ui/molecules/text";
import { useReservationsHook } from "../../../../hooks/reservation-hook";
import { useMakeReservation } from "../../../../hooks/reservations/make-reservation";
import { Driver, PublishTicketDto, Trip } from "../../../../sdk/catalog";
import { useDisclosure } from "@mantine/hooks";
import { formatCurrency, friendlyLocationStr } from "../../../../utils";
import { TickCircle, User } from "iconsax-react";
import { bookingsManager } from "@store/bookings-manager";
import { reservationManager } from "@store/my-reservations";
import { useForm } from "@mantine/form";
import { useState } from "react";
import { ticketsController } from "../../../../config/sdk";
import { ticketsManager } from "@store/tickets";
import { showNotification } from "@mantine/notifications";

export function PostTicketProcess() {
  const [opened, { open, close }] = useDisclosure(false);

  let [subject, setSubject] = useState("");

  let [issue, setIssue] = useState("");
  return (
    <Box>
      <Modal
        size={"lg"}
        opened={opened}
        onClose={() => {
          close();
        }}
      >
        <Stack>
          <TextInput
            label="Subject"
            onChange={(e) => {
              setSubject(e.target.value ?? "");
            }}
          />
          <Textarea
            label="Explain issue "
            onChange={(e) => {
              setIssue(e.target.value ?? "");
            }}
          ></Textarea>
          <Button
            mt="12px"
            fullWidth
            onClick={() => {
              if (subject.length < 5 || issue.length < 5) {
                showNotification({
                  message: "Subject & issue should be meaningful...",
                });
                return;
              } else {
                ticketsController
                  .ticketControllerCreateTicket({
                    issue: issue ?? "",
                    subject: subject ?? "",
                  })
                  .then((response) => {
                    ticketsManager.loadItems();
                    showNotification({
                      message: "Request completed successfully.",
                    });
                    setIssue("");
                    setSubject("");
                    close();
                  });
              }
            }}
          >
            Submit
          </Button>
        </Stack>
      </Modal>

      <Button
        size="sm"
        onClick={() => {
          open();
        }}
      >
        Open Ticket
      </Button>
    </Box>
  );
}
