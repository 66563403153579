import { Box, Container, Stack } from "@mantine/core";
import { SectionHeader } from "@ui/organisms/common-widgets/SectionHeader";
import { RenderPlatformBanner } from "@ui/organisms/utils/covers";
import ReactMarkdown from "react-markdown";
import { useUi } from "../../../hooks/use-ui";

export default () => {
  useUi();

  return (
    <Box>
      <RenderPlatformBanner title="Cancellation policy" />

      <Container>
        <ReactMarkdown
          children={`
## Cancellation Policy for Drivers and Passengers on Etoju:

At Etoju, we understand that circumstances may arise where drivers or passengers need to cancel a trip. To ensure transparency and fairness, we have implemented a cancellation policy that outlines the rights and responsibilities of both drivers and passengers in such situations.

## Driver Cancellation:  
1\. If a driver cancels a trip after 12 hours of accepting the request, they will receive a warning from Etoju. We encourage drivers to honor their commitments to provide a reliable and dependable service to passengers.

2\. Passengers will receive a full refund of their payment if a driver cancels the trip after the 12-hour mark. We strive to prioritize passenger satisfaction and ensure that they are not inconvenienced by last-minute cancellations.

## Passenger Cancellation:  
1\. If a passenger cancels a trip after 12 hours of the driver accepting the request, the following policy applies:  
  - 50% of the payment will be deducted from the passenger's refund.  
  - The remaining 50% will be paid to the driver as compensation for their time and effort.

2\. However, if a passenger cancels a trip before 12 hours of the driver accepting the request, they will receive a full refund. We encourage passengers to make any necessary changes to their travel plans as early as possible to minimize any inconvenience caused to the driver.

Our cancellation policy is designed to strike a balance between protecting the interests of both drivers and passengers. By implementing this policy, we aim to foster trust and reliability within the Etoju community. We strongly recommend all users to review and adhere to the cancellation policy to ensure a smooth and satisfactory experience for everyone involved.

Please note that Etoju reserves the right to make exceptions to the cancellation policy in exceptional circumstances or emergencies. We continuously monitor the platform to ensure compliance and take appropriate actions against any abuse or misuse of the cancellation policy.

We value the trust and cooperation of our users and strive to provide a reliable and enjoyable experience on the Etoju platform.`}
        />
      </Container>
    </Box>
  );
};
