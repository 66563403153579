import { makeAutoObservable, observable, runInAction } from "mobx";
import { authController, configureClientSDK } from "../../config/sdk";
import { UserPublicData } from "../../sdk/auth";

class AuthManager {
  //@ts-ignore
  user: UserPublicData = {};
  status: "AUTHENTICATED" | "INITIAL" | "LOADED" = "INITIAL";
  constructor() {
    makeAutoObservable(this, { user: observable });
  }

  initAccountToken(token: string) {
    localStorage.setItem("u-token", token);
    this.init();
  }

  async init() {
    let token = localStorage.getItem("u-token");
    if (token) {
      configureClientSDK(token);
      runInAction(() => {
        this.status = "AUTHENTICATED";
      });
      await this.loadProfile();
    }
  }

  logout() {
    localStorage.clear();
    runInAction(() => {
      this.status = "LOADED";
      //@ts-ignore
      this.user = {};
    });
  }

  async loadProfile() {
    try {
      let response = await authController.authControllerGetProfile();
      runInAction(() => {
        this.user = response.data.user;
        this.status = "AUTHENTICATED";
      });
    } catch (err) {
      //@ts-ignore
      console.log(err.response, "error goes here");

      runInAction(() => {
        this.status = "LOADED";
      });
    }
  }
}

export const authManager = new AuthManager();
