import { useState } from "react";
import { } from "@mantine/hooks";
import { useForm } from "@mantine/form";
import {
  TextInput,
  PasswordInput,
  Text,
  Button,
  Title,
  Stack,
  Box,
  Container,
  Grid,
  Select,
  Paper,
} from "@mantine/core";
import { registerationManager } from "../../../store/account/registerationManager";
import { Link, Navigate } from "react-router-dom";
import { RenderPlatformBanner } from "@ui/organisms/utils/covers";
import { authManager } from "@store/account/auth";
import { observer } from "mobx-react";
import { SignUpDto } from "../../../sdk/auth";
import { useUi } from "../../../hooks/use-ui";

export default observer((props: any) => {
  useUi()
  let [submitting, setSubmitting] = useState(false);
  const form = useForm<SignUpDto>({
    initialValues: {
      first_name: "",
      last_name: "",
      phone: "",
      account_type: "DRIVER",
      email: "",
      password: "",
    },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
      password: (value) =>
        value.length >= 6 ? null : "Password characters must be more than 6",
    },
  });

  console.log(authManager.user)
  if (authManager?.user?.id !== undefined) {
    return <Navigate to={"/"} />;
  }

  return (
    <Box sx={{ background: "ghostwhite" }}>
      <RenderPlatformBanner />
      <Container size={"md"} py="42px">
        <Stack spacing={"xl"}>
          <Title sx={{ textAlign: "center" }}>
            {form.values.account_type == "INDIVIDUAL"
              ? "Enjoy your ride with Etoju"
              : " Drive with Etoju and make extra cash"}
          </Title>
          <form
            onSubmit={form.onSubmit(async (values) => {
              try {
                setSubmitting(true);
                await registerationManager.createAccount({
                  ...values,
                });
                setSubmitting(false);
              } catch (e) {
                setSubmitting(false);
              }
            })}
          >
            <Paper p="lg" shadow={"md"}>
              <Stack spacing={"xl"}>
                <Grid>
                  <Grid.Col md={6}>
                    <TextInput
                      label="First Name"
                      size="lg"
                      placeholder="Type Here"
                      {...form.getInputProps("first_name")}
                    />
                  </Grid.Col>
                  <Grid.Col md={6}>
                    <TextInput
                      size="lg"
                      label="Last Name"
                      placeholder="Type here"
                      {...form.getInputProps("last_name")}
                    />
                  </Grid.Col>
                  <Grid.Col md={12}>
                    <Grid>
                      <Grid.Col md={6}>
                        <TextInput
                          size="lg"
                          label="Phone Number"
                          placeholder="Type here"
                          {...form.getInputProps("phone")}
                        />
                      </Grid.Col>

                      <Grid.Col md={6}>
                        <TextInput
                          label="Email"
                          size="lg"
                          placeholder="Type here"
                          {...form.getInputProps("email")}
                        />
                      </Grid.Col>
                    </Grid>
                  </Grid.Col>
              
                  <Grid.Col md={12}>
                    <PasswordInput
                      label="Password"
                      size="lg"
                      placeholder="Type Here"
                      {...form.getInputProps("password")}
                    />
                  </Grid.Col>
                </Grid>

                <Button
                  fullWidth
             
                  loading={submitting}
                  type="submit"
                  size="lg"

                >
                  Continue
                </Button>
              </Stack>
            </Paper>
          </form>
          <Text sx={{ textAlign: "center" }}>
            Already have an account?{" "}
            <Link to="/login">
              <span style={{ fontWeight: "bold" }}>Signin</span>
            </Link>
          </Text>
        </Stack>
      </Container>
    </Box>
  );
});
