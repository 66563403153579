import { Box, Container, Stack } from "@mantine/core";
import { SectionHeader } from "@ui/organisms/common-widgets/SectionHeader";
import { RenderPlatformBanner } from "@ui/organisms/utils/covers";
import ReactMarkdown from 'react-markdown'
import { useUi } from "../../../hooks/use-ui";
export default () => {
  useUi()
  return (
    <Box>
      <RenderPlatformBanner title="Privacy Policy" />

    <Container>
    <ReactMarkdown
        children={`
At Etoju, we are committed to protecting your privacy. This Privacy Policy outlines the types of information we collect when you use our services, how we use this information, and how we safeguard your personal data.

## Information We Collect

We collect personal information that you provide to us when you use our website and services, such as your name, contact information, and payment details. We may also collect information about your location, device, and usage of our services through cookies and other tracking technologies.

## How We Use Your Information

We use your personal information to provide and improve our services, process payments, and communicate with you about your account and any updates to our services. We may also use your information for marketing and promotional purposes, but you can opt out of these communications at any time.

## Sharing Your Information

We may share your personal information with third-party service providers that help us operate our services and process payments. We may also share your information with law enforcement agencies, government officials, or other third parties as required by law or to protect our legal rights.

## Data Security

We take appropriate measures to protect your personal data from unauthorized access, alteration, disclosure, or destruction. We use industry-standard encryption and security protocols to safeguard your information.

## Changes to This Policy

We may update this Privacy Policy from time to time to reflect changes in our practices or applicable laws. We encourage you to review this policy periodically to stay informed about how we collect and use your personal information.

If you have any questions or concerns about our Privacy Policy, please contact us at [contact@Etoju.com](mailto:contact@Etoju.com).
`} />
    </Container>

    </Box>
  );
};
