import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useNavigate,
  useSearchParams,
  useLocation,
  Navigate,
} from "react-router-dom";
import { DashboardScreen } from "@ui/pages/dashboard";
import { Suspense, useEffect } from "react";
import React from "react";
import { LoadingOverlay } from "@mantine/core";
import { DefaultShell } from "@ui/template/DefaultShell";
import { AboutScreen } from "@ui/pages/public/AboutPage";
import ContactPage from "@ui/pages/public/ContactPage";
import TermsAndConditionsPage from "@ui/pages/public/TermsAndConditionsPage";
import PrivacyPolicyPage from "@ui/pages/public/PrivacyPolicyPage";
import FAQPage from "@ui/pages/public/FAQPage";
import HowTos from "@ui/pages/public/HowTos";
import RefundPolicyPage from "@ui/pages/public/RefundPolicyPage";
import NewsletterPage from "@ui/pages/public/NewsletterPage";
import VerifyAccountPage from "@ui/pages/public/VerifyAccountPage";
import ActivateAccountPage from "@ui/pages/public/ActivateAccountPage";
import ResetPasswordPage from "@ui/pages/public/ResetPasswordPage";
import TicketsScreen from "@ui/pages/tickets/TicketsScreen";
import ManageTicket from "@ui/pages/manage-ticket/ManageTicket";
import GetstartedPage from "@ui/pages/public/GetstartedPage";
import DriverRegisterationScreen from "@ui/pages/authentication/DriverRegisterationScreen";
import { authManager } from "@store/account/auth";
import { observer } from "mobx-react";
import CancellationPolicyPage from "@ui/pages/public/CancellationPolicyPage";

const LoginScreen = React.lazy(
  () => import("@ui/pages/authentication/LoginScreen")
);

const RegisterationScreen = React.lazy(
  () => import("@ui/pages/authentication/RegisterationScreen")
);
const ContactScreen = React.lazy(() => import("@ui/pages/public/ContactPage"));
const EditProfileScreen = React.lazy(
  () => import("@ui/pages/account/profile/ProfileSettings")
);

const AccountShell = React.lazy(() => import("@ui/template/AccountShell"));
const DriverDashboardShell = React.lazy(
  () => import("@ui/template/DriverDashboardShell")
);
const HowItWorksScreen = React.lazy(
  () => import("@ui/pages/public/HowItWorksPage")
);
const MyBookingsScreen = React.lazy(
  () => import("@ui/pages/account/bookings/MyBookingsScreen")
);
const SupportScreen = React.lazy(
  () => import("@ui/pages/account/SupportScreen")
);
const TripsScreen = React.lazy(
  () => import("@ui/pages/driver/trips/TripsScreen")
);
const PayoutsScreen = React.lazy(
  () => import("@ui/pages/driver/payouts/PayoutsScreen")
);
const DriverVerification = React.lazy(
  () => import("@ui/pages/driver/verification/DriverVerifcationScreen")
);
const KYCScreen = React.lazy(() => import("@ui/pages/driver/KYCScreen"));
const ViewPublicTripScreen = React.lazy(
  () => import("@ui/pages/public/trip/ViewPublicTripScreen")
);
const ManageTripDetailsScreen = React.lazy(
  () => import("@ui/pages/driver/manage-trip/DriverManageTripScreen")
);
const FindJobsScreen = React.lazy(
  () => import("@ui/pages/driver/jobs/FindJobsScreen")
);
const JobDetailsScreen = React.lazy(
  () => import("@ui/pages/driver/jobs/JobDetailsScreen")
);
const RequestsScreen = React.lazy(
  () => import("@ui/pages/account/requests/RequestsScreen")
);
const RequestTripScreen = React.lazy(
  () => import("@ui/pages/account/requests/RequestTripScreen")
);
const PostTripScreen = React.lazy(
  () => import("@ui/pages/driver/post-trip/PostTripScreen")
);
const PublicTripsScreen = React.lazy(() => import("@ui/pages/public/trips"));
const DriverSettingsScreen = React.lazy(
  () => import("@ui/pages/driver/settings/DriverSettingsScreen")
);

const DriverDashboard = React.lazy(
  () => import("@ui/pages/driver/dashboard/DriverDashboard")
);

const LandingPage = React.lazy(
  () => import("@ui/pages/public/home/LandingPage")
);



export default observer(function MainAppRoutes() {
  const useQuery = () => new URLSearchParams(useLocation().search);
  let query = useQuery();
  const navigate = useNavigate();
  useEffect(() => {
    authManager.init()
  }, []);

  return (
    <Suspense fallback={<LoadingOverlay visible={true} />}>
      <Routes>
        <Route path="account/driver" element={<DriverDashboardShell />}>
          <Route path="settings" element={<DriverSettingsScreen />} />
          <Route path="trips" element={<TripsScreen />} />
          <Route path="tickets" element={<TicketsScreen />} />
          <Route path="tickets/:ticket_id" element={<ManageTicket />} />
          <Route path="post-trip" element={<PostTripScreen />} />
          <Route path="trips/:trip_id" element={<ManageTripDetailsScreen />} />
          <Route path="find-jobs" element={<FindJobsScreen />} />
          <Route path="find-jobs/:job" element={<JobDetailsScreen />} />
          <Route path="payouts" element={<PayoutsScreen />} />
          <Route path="kyc" element={<KYCScreen />} />
          <Route path="driver-verification" element={<DriverVerification />} />
          <Route path="" element={<DriverDashboard />} />
        </Route>
        <Route path="account" element={<AccountShell />}>
          <Route path="settings" element={<EditProfileScreen />} />
          <Route path="request-trip" element={<RequestTripScreen />} />
          <Route path="support" element={<SupportScreen />} />
          <Route path="requests" element={<RequestsScreen />} />
          <Route path="tickets" element={<TicketsScreen />} />
          <Route path="find-trips" element={<PublicTripsScreen />} />
          <Route path="tickets/:ticket_id" element={<ManageTicket />} />
          <Route path="bookings" element={<MyBookingsScreen />} />
          <Route path="" element={<DashboardScreen />} />
        </Route>
        <Route path="" element={<DefaultShell />}>
          <Route path="driver-verification" element={<DriverVerification />} />
          <Route path="verify" element={<VerifyAccountPage />} />
          <Route
            path="verify/:userId/:activationToken"
            element={<ActivateAccountPage />}
          />
          <Route
            path="reset-password/:passwordResetToken"
            element={<ResetPasswordPage />}
          />
          <Route path="refund-policy" element={<RefundPolicyPage />} />
          <Route path="about" element={<AboutScreen />} />
          <Route path="contact" element={<ContactPage />} />
          <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="cancellation-policy" element={<CancellationPolicyPage />} />
          <Route path="faqs" element={<FAQPage />} />
          <Route path="how-tos" element={<HowTos />} />
          <Route path="t-and-c" element={<TermsAndConditionsPage />} />
          <Route path="how-it-works" element={<HowItWorksScreen />} />
          <Route path="find" element={<ContactScreen />} />
          <Route path="trips" element={<PublicTripsScreen />} />
          <Route path="trips/:trip_id" element={<ViewPublicTripScreen />} />
          <Route path="getstarted" element={<GetstartedPage />} />
          <Route path="driver-signup" element={<DriverRegisterationScreen />} />
          <Route path="signin" element={<LoginScreen />} />
          <Route path="signup" element={<RegisterationScreen />} />
          <Route path="" element={<LandingPage />} />
        </Route>
        <Route path="newsletter" element={<NewsletterPage />} />
      </Routes>
    </Suspense>
  );
});

const AccountRoutes = observer(function () {
  if (authManager?.status == "INITIAL") {
    return <LoadingOverlay visible={true} />;
  }

  if (authManager?.status == "LOADED") {
    return <Navigate to={"/"} />;
  }

  return <React.Fragment></React.Fragment>;
});
