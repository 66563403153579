import { Box, Container, Stack } from "@mantine/core";
import { SectionHeader } from "@ui/organisms/common-widgets/SectionHeader";
import { RenderPlatformBanner } from "@ui/organisms/utils/covers";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { useUi } from "../../../hooks/use-ui";

export default () => {
  useUi()
  return (
    <Box>
      <RenderPlatformBanner title="How To's" />

      <Container>
        <ReactMarkdown children={`

Welcome to the Etoju-To's page! Here you'll find helpful guides to help you get the most out of our app and service.

## How to Book a Ride

1. Open the Etoju app or website and sign in or create an account if you haven't already.
2. Enter your pickup and dropoff locations and select your preferred ride option.
3. Review the fare estimate and confirm your booking.
4. Wait for a driver to accept your booking request.
5. Once a driver is assigned, you'll receive their details and estimated time of arrival.
6. Meet your driver at the pickup location and enjoy your ride!

## How to Post a Trip

1. Open the Etoju app or website and sign in or create an account if you haven't already.
2. Tap the "Post a Trip" button on the home screen.
3. Enter your trip details, including pickup and dropoff locations, trip date and time, and number of available seats.
4. Review your trip details and confirm your post.
5. Wait for passengers to book your trip.

## How to Cancel a Ride

1. Open the Etoju app and go to your upcoming bookings.
2. Select the booking you wish to cancel.
3. Tap the "Cancel Ride" button and confirm your cancellation.
4. The cancellation policy will apply based on the time of cancellation.

## How to Contact Customer Support

If you have any questions or issues with our service, our customer support team is available to help you. You can contact us in the following ways:

- In-app support chat: Tap the "Help" button in the app and start a chat with our support team.
- Email: Send an email to [support@Etoju.com](mailto:support@Etoju.com) and our team will respond within 24 hours.
- Phone: Call our customer support hotline at 1-800-123-4567 during business hours.

We hope these How-To's are helpful! If you have any suggestions for additional topics, please let us know.
`} />
      </Container>
    </Box>
  );
};
