import { Box, Center, Stack, Text } from "@mantine/core";
import { Trip } from "../../../../../sdk/catalog";
import { HorizontalKeyValuePair } from "@ui/molecules/text";
import { TickCircle } from "iconsax-react";
import { friendlyLocationStr, formatCurrency } from "../../../../../utils";

export function OnReservationSuccessful({
  trip,
  reserveSeats,
}: {
  trip: Trip;
  reserveSeats: any[];
}) {
  return (
    <Box>
      <Stack sx={{ textAlign: "center" }}>
        <Center>
          <TickCircle size={100} color="green" variant="Bulk" />
        </Center>
        <Box>
          <HorizontalKeyValuePair
            label="From"
            value={friendlyLocationStr(trip.start_location)}
          />
          <HorizontalKeyValuePair
            label="To"
            value={friendlyLocationStr(trip.end_location)}
          />
          <HorizontalKeyValuePair
            label="Seats"
            value={reserveSeats.length + ""}
          />
          <HorizontalKeyValuePair
            label="Price Per seat"
            value={formatCurrency(trip.trip_spec.price_per_seat)}
          />
        </Box>
        <Text color="dimmed">
          Wait for your reservation to be accepted by driver
        </Text>
      </Stack>
    </Box>
  );
}
