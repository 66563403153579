import { showNotification } from "@mantine/notifications";
import { completeNavigationProgress } from "@mantine/nprogress";
import { AxiosError, AxiosResponse } from "axios";
import { Location } from "../sdk/catalog";

let currencyFormatter = require("currency-formatter");

//
export function formatCurrency(val: number) {
  return currencyFormatter.format(val, { code: "NGN" });
}

export function handleAxiosError(err: any) {
  completeNavigationProgress();
  let error: AxiosError = err as AxiosError;
  console.log(error.response);

  if (error.response?.data.message) {
    showNotification({
      message: error.response?.data.message ?? "Something went wrong",
      title: error.response?.data.error,
    });
  }
  return "Message";
}

export function handleAxiosResponse<T>(response: AxiosResponse): T {
  let data = response.data;

  if (data.responseBody) {
    return data.responseBody;
  }
  return data;
}

export function handleSuccessEvent(message?: string) {
  showNotification({
    message: message ?? "Action completed successfully",
  });
}

export function percentageOfANumber(num: number, per: number) {
  return (num / 100) * per;
}

export function friendlyLocationStr(location: Location) {
  return `${location.address}`;
}

export function getMapUrl(
  fromlong: number,
  fromlat: number,
  tolong: number,
  tolat: number
) {
  let urlP1 = `https://maps.googleapis.com/maps/api/staticmap?size=320x176&amp;maptype=roadmap&amp;markers=color:red|label:A|size:mid|${
    fromlong ?? 0
  },${fromlat ?? 0}&amp;markers=color:green|label:B|size:mid|${tolong ?? 0},${
    tolat ?? 0
  }`;
  let url =
    urlP1 +
    "&amp;path=color:0x8078baff|weight:3|enc:{ahf@eg_Tw@@]Ea@JSLMv@RoC~F{AvCcDfFOZ{DfJiAfC}A|BuBnCm@rAYdAG~@BnBJt@^bBx@tDl@rC^`DBTCxBUdAElCE`C?bQ?pQ?TBNF@r@BJRNXAJJXJxFd@@SgDSw@K]OQWCYQaAO?ODGFGBz@BFVLNAJFLF~AT`FZtGl@rARrAXbCr@rHpCbKzDVPDROnMD|DkGBmN?y[@eLBqLEmAI{BY_E_AsHcCeJ{CaHcCWQg@QeA_@{Ae@cD{@UIKK]Oa@@SJKZCZFZPPXBRAI`@KF@p@LtA^dBh@pAb@j@HxQzFlDjAdDjAd@`@NTHXAj@Wd@e@^kAb@WHHTz@|BbArDz@lEz@fHl@tE^bEF|BC~BKdBc@pDSlA]lAc@xAqAbDeA`Cy@hCUbAE~@?nAJbANx@Tr@f@jAbAhCCBCFADi@b@eB|AcAtAgAjB]fA{CzEkB~CoAvBiAlBaA~AiArAsBxByAzAaAlAW@DDPNT`@NHFBDCHIhBkB~AuBd@cAVcALeAHmAAgAMmAYkAY}@c@_AiAsB_CaCk@e@iCiBmBkAcDqA_EmAgBWyAOqEa@kFm@_CUsJgAgHu@eB?eCJgBVmCr@cA^{A|@{AnAo@r@q@x@g@x@q@|A_@jAWlAQtAQfDQ~GOpC]bDaAxEy@dCs@hBiAxByAfCqCdE}GlIoAvAeB`BqB|A}E|CkD|AoHxBmDz@wE|@{I|@qJ^wECcEMuE[cGy@eEu@aEaA}DmAoCkAqB{@k@_@MQWSgDiBiAeAkA{Ao@q@sAw@gA_@qAQ}ACo@HcBX}@Zm@XmB`AuAbAo@l@]^cArAsBtCk@l@wCxCaAbAwBvCw@jAy@zA]t@m@`Bg@jBk@zDMfC@tABfBNbBR|Ax@zChA`Dv@zBm@Rw@TqAn@}ErAuGpBCIU}@T|@BHiCr@oD|@kARSHMHYh@}@|BY^SPuCx@mOvEqCx@y@Vi@Du@Ao@E}@KiAYcNuA}A[cAWuAOcFo@k@BuCr@gBl@}HzBoGtBiKtCcBb@q@XeA^u@b@]`@Uf@o@hB[`AyA|EkBhGQv@?v@TdAv@vC~AxEKh@IVaAZeCz@sDnAuBPu@AmOJiCBmA?s@AuAFmGBym@Zek@ZcMDgNLw]PkLHezAt@gDCiGS{Go@_Dk@wBi@uC_AeBo@}CuAsDsBkIsF_X}P{LcIwFsD_@s@SSmEqC{I}FuHcF&amp;key=AIzaSyCBguFgxPOH6AuAiz0ZXXoo_fJAp4AR8WE";
  return url;
}

export const APPMOMENT = require("moment");
//APPMOMENT.defaultFormat = "Do MMM - hh:mm a";
APPMOMENT.defaultFormat = "LLLL";


export const carCompanyNames = [
  'Acura',
  'Alfa Romeo',
  'Audi',
  'Bentley',
  'BMW',
  'Bugatti',
  'Cadillac',
  'Chevrolet',
  'Chrysler',
  'Dodge',
  'Ferrari',
  'Fiat',
  'Ford',
  'Genesis',
  'Honda',
  'Hyundai',
  'Infiniti',
  'Jaguar',
  'Kia',
  'Lamborghini',
  'Land Rover',
  'Lexus',
  'Lincoln',
  'Maserati',
  'Mazda',
  'McLaren',
  'Mercedes-Benz',
  'Mini',
  'Mitsubishi',
  'Nissan',
  'Porsche',
  'Renault',
  'Rolls-Royce',
  'Subaru',
  'Suzuki',
  'Tesla',
  'Toyota',
  'Volkswagen',
  'Volvo'
];
