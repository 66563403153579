import { Box, Container, Stack } from "@mantine/core";
import { RenderPlatformBanner } from "@ui/organisms/utils/covers";
import { useUi } from "../../../hooks/use-ui";

export default () => {
  useUi()
  return (
    <Box>
      <RenderPlatformBanner />
    </Box>
  );
};
