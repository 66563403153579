import { Box, Container, Stack } from "@mantine/core";
import { SectionHeader } from "@ui/organisms/common-widgets/SectionHeader";
import { RenderPlatformBanner, RenderPlatformFancyBanner } from "@ui/organisms/utils/covers";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { useUi } from "../../../hooks/use-ui";

export default () => {
  useUi()
  return (
    <Box>
      <RenderPlatformFancyBanner title="Frequently Asked Questions" caption="Welcome to the Etoju FAQ page! Here you'll find answers to the most common questions about our app and service." />

      <Container>
        <ReactMarkdown children={`
## General Questions

### What is Etoju?

Etoju is a ride-sharing platform that connects drivers with passengers who are traveling in the same direction.

### How does Etoju work?

Passengers can book a ride using our app or website, and drivers can post available trips for passengers to book. We provide a convenient and affordable way for people to travel together.

### Is Etoju available in my city?

We're constantly expanding our service to new cities, so please check the app or website for the latest availability.

## Passenger Questions

### How do I book a ride on Etoju?

To book a ride on Etoju, simply enter your pickup and dropoff locations and select your preferred ride option. You can review the fare estimate before confirming your booking.

### How do I pay for my Etoju ride?

We accept all major credit and debit cards, and payment is processed securely through our app or website.

### Can I cancel a Etoju ride?

Yes, you can cancel a ride up to a certain time before the scheduled pickup time. Please check our cancellation policy for more details.

## Driver Questions

### How do I become a Etoju driver?

To become a Etoju driver, you'll need to sign up on our website or app and complete our driver registration process. This includes submitting your personal and vehicle information, completing a background check, and agreeing to our terms and conditions.

### How do I post a trip on Etoju?

To post a trip on Etoju, simply tap the "Post a Trip" button on the home screen and enter your trip details. You can set the pickup and dropoff locations, trip date and time, and number of available seats.

### How do I get paid for my Etoju trips?

We process driver payments securely through our app or website, and payment is sent to your designated account within a certain time frame after each completed trip.

## Contact Us

If you have any other questions or issues, please don't hesitate to contact us. You can reach us by email at [support@Etoju.com](mailto:support@Etoju.com), or through our in-app support chat.

We hope this FAQ has been helpful! If you have any suggestions for additional topics, please let us know.`} />
      </Container>
    </Box>
  );
};
