import { Box, Button, Container, Grid, Paper, Stack, Title, Text, Center } from "@mantine/core";
import { RenderPlatformBanner } from "@ui/organisms/utils/covers";
import { observer } from "mobx-react";
import { authManager } from "@store/account/auth";
import { Verify } from "iconsax-react";
import { authController } from "../../../config/sdk";
import { showNotification } from "@mantine/notifications";
import { useNavigate } from "react-router-dom";
import { useUi } from "../../../hooks/use-ui";

export default observer(() => {
  const navigate = useNavigate();
  useUi()
  return (
    <Box>
      <RenderPlatformBanner title="Verify account" caption="" />
      <Stack>
        <Container>
          <Grid justify="center">
            <Grid.Col md={10} sx={{ textAlign: "center" }}>
              <Stack>
                <Center>
                  <Verify color="gray" size={100} variant="Bulk" />
                </Center>
                <Title>Verify your email address</Title>
                <Text>An activation link was sent to  {authManager?.user?.email} on signup. Check you email for email sent and click on the activation link/button.   You can use the button below to resend activation link to your email </Text>
                <Button color="red" size="lg" onClick={() => {
                  authController.authControllerResendActivationToken().then(res => {
                    showNotification({ message: "Activation link has been sent to registered email address" })
                  });
                }}>Resend Link</Button>
                <Button color="gray" variant="subtle" size="lg" onClick={() => {
                  localStorage.clear()
                  authManager.logout();
                  navigate("/");
                }}>Logout</Button>


              </Stack>
            </Grid.Col>
          </Grid>
        </Container>
      </Stack>

    </Box>
  );
})
